import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
`;

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexColumnCentered = styled(FlexColumnContainer)`
  align-self: stretch;
  align-items: center;
`;

export const FlexColumnSpaceBetween = styled(FlexColumnCentered)`
  flex-grow: 1;
  justify-content: space-between;
`;

export const BorderBottomContainer = styled.div`
  width: 100%;
  border-bottom: 0.6px solid #d1dade;
  padding: 24px;
  box-sizing: border-box;
`;
