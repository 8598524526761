import * as R from 'ramda';
import { AssetStatuses } from 'poly-constants';

export const QR_CODE_SCANNER_STATUSES = {
  found: 'qrCodeFound',
  new: 'newQRCode',
  incorrect: 'incorrect',
};

export const QR_CODE_SCANNER_STATUSES_COLORS = {
  [QR_CODE_SCANNER_STATUSES.found]: '#1ab394',
  [QR_CODE_SCANNER_STATUSES.new]: '#d19c19',
  [QR_CODE_SCANNER_STATUSES.incorrect]: '#e75858',
};

// validateCode :: String -> Boolean
export const isCodeValid = R.test(/[a-zA-Z0-9]{6}/);

export const scannerContainerId = 'scanner-container-id';

// isAssetInactive :: Asset -> Boolean
export const isAssetInactive = R.converge(R.includes, [
  R.prop('status'),
  R.always([AssetStatuses.INACTIVE, AssetStatuses.PENDING]),
]);
