import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';

const assetByQrCodeIdQuery = gql`
  query assetByQrCodeIdQuery($input: AssetByQrCodeIdInput!) {
    assetByQrCodeId(input: $input) {
      _id
      status
      qrCodeId
    }
  }
`;

const getAssetFromQueryData = R.prop('assetByQrCodeId');

export const useAssetByQrCodeQuery = (qrCodeId) => {
  const { data, loading } = useQuery(assetByQrCodeIdQuery, {
    fetchPolicy: 'network-only',
    variables: { input: { qrCodeId, rejectOnNull: false } },
    skip: !qrCodeId,
  });

  return { asset: getAssetFromQueryData(data), loading };
};

const isQrCodeUsedQuery = gql`
  query isQrCodeUsedQuery($qrCodeId: String!) {
    isQrCodeUsed(qrCodeId: $qrCodeId)
  }
`;

export const useIsQrCodeUsedQuery = (qrCodeId, skip) => {
  const { data, loading } = useQuery(isQrCodeUsedQuery, {
    variables: { qrCodeId },
    fetchPolicy: 'network-only',
    skip,
  });
  return { isQrCodeUsed: data?.isQrCodeUsed, loading };
};
