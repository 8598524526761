import styled from 'styled-components';

export const StaticHolderWrapperS = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 130px);
`;

export const StaticHolderTextS = styled.div`
  font-size: 14px;
  color: #12347a;
  margin-top: 10px;
  font-weight: 500;
  max-width: 200px;
  line-height: 20px;
  text-align: center;
`;

export const StaticHolderTitleS = styled(StaticHolderTextS)`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
`;
