import ImageGallery from 'react-image-gallery';
import styled, { css } from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useNavigate, useLocation } from 'poly-client-routing';
import { getThemeColor } from 'poly-book-admin';
import { useCurrentUserQuery } from 'poly-client-utils';

import { routes } from '../routes.js';
import { Text } from '../components/Text.js';
import { Button } from '../components/Button.js';

const sliderConfig = [
  {
    original: '/images/onboarding-1.png',
  },
  {
    original: '/images/onboarding-2.png',
  },
];

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 275px;
  z-index: 2;
  position: absolute;
  bottom: 0;
  background: #fff;
  box-shadow: 0px -4px 26px rgba(0, 0, 0, 0.15);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 8px 24px 32px 24px;
  box-sizing: border-box;

  ::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 4px;
    background: #e1e4e7;
    border-radius: 31px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 25px;
`;

const sliderStyles = css`
  .image-gallery-slide {
    padding: 60px 40px;
    box-sizing: border-box;
  }

  .image-gallery,
  .image-gallery-content,
  .image-gallery-slide-wrapper,
  .image-gallery-swipe,
  .image-gallery-slides {
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .image-gallery-bullets {
    margin-bottom: 110px;
  }

  .image-gallery-bullets-container > .image-gallery-bullet {
    width: 9px;
    height: 9px;
    background-color: ${getThemeColor(['midLight'])};
    box-shadow: none;
    margin-right: 15px;
  }

  .image-gallery-bullets-container > .image-gallery-bullet.active {
    width: 28px;
    height: 9px;
    background: #00dedc;
    border-radius: 5px;
  }
`;

const OnboardingContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${sliderStyles}
`;

const setOnboardingCompleteMutation = gql`
  mutation setOnboardingCompleteMutation {
    setOnboardingComplete {
      _id
    }
  }
`;

const slidesTextConfig = [
  {
    title: 'Find the Assets',
    message:
      "Locate the asset of interest. Search for our QR tag, it's usually on the face of the asset",
  },
  {
    title: 'Scan the Code',
    message:
      "Point your phone's camera to the QR code. If you have any issues enter the code manually",
  },
];

export function OnboardingScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [setOnboardingComplete] = useMutation(setOnboardingCompleteMutation);
  const { user } = useCurrentUserQuery();

  useEffect(
    () => () => {
      if (user?._id) {
        setOnboardingComplete();
      }
    },
    [],
  );

  return (
    <OnboardingContainer>
      <BottomContainer>
        <MessageContainer>
          <Text size={22}>{slidesTextConfig[currentSlide].title}</Text>
          <Text size={14} color="mainDark" margin="12px 25px" align="center">
            {slidesTextConfig[currentSlide].message}
          </Text>
        </MessageContainer>
        <Button
          caption="Start Scanning"
          iconName="camera"
          onClick={() => navigate(routes.scanQrCode + search)}
        />
      </BottomContainer>
      <ImageGallery
        showFullscreenButton={false}
        onSlide={setCurrentSlide}
        showPlayButton={false}
        showNav={false}
        items={sliderConfig}
        showBullets
        infinite
        autoPlay
      />
    </OnboardingContainer>
  );
}
