import React from 'react';
import { node } from 'prop-types';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';

import {
  projectReducer,
  searchProjectsFilterReducer,
} from '../redux/project/reducer.js';
import { listScrollReducer } from '../redux/listScroll/reducer.js';
import { searchHistoryReducer } from '../redux/searchHistory/reducer.js';
import { syncQueueStatusReducer } from '../redux/queueSync/reducer.js';
import { globalClientReducer } from '../redux/currentClient/reducer.js';

const store = createStore(
  combineReducers({
    project: projectReducer,
    listScroll: listScrollReducer,
    searchHistory: searchHistoryReducer,
    searchProjectsFilter: searchProjectsFilterReducer,
    syncQueueStatus: syncQueueStatusReducer,
    globalClient: globalClientReducer,
  }),
);

export function StoreProvider({ children }) {
  return <Provider store={store}>{children}</Provider>;
}

StoreProvider.propTypes = {
  children: node.isRequired,
};
