import { SET_SYNC_QUEUE_STATUS } from './actions.js';

export const defaultSyncQueueStatusReducerState = {
  status: null,
  itemsCount: 0,
  isSyncing: false,
};

export const syncQueueStatusReducer = (state, action) => {
  if (action?.type === SET_SYNC_QUEUE_STATUS) {
    return { ...state, ...action.payload };
  }

  return state || defaultSyncQueueStatusReducerState;
};
