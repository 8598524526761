import { useEffect, useState } from 'react';

import { assetScannerIndexedDb } from '../../offline/indexedDb/indexedDb.js';
import { assetsStoreName } from '../../offline/indexedDb/indexedDbStores.js';

export const useAssetFromIndexedDb = (assetIdbKey, dependencies = []) => {
  const [asset, setAsset] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAssetsQueue = async () => {
      if (assetIdbKey) {
        const iDb = await assetScannerIndexedDb();

        const cashedAssets = await iDb.getById(assetsStoreName, assetIdbKey);

        setAsset(cashedAssets);
      }

      setLoading(false);
    };

    getAssetsQueue();
  }, [assetIdbKey, ...dependencies]);

  return { asset, loading };
};
