import styled from 'styled-components';
import * as R from 'ramda';
import { getThemeColor, getThemeFont } from 'poly-book-admin';

export const Text = styled.span`
  font-family: ${getThemeFont(['defaultFont'])};
  font-style: normal;
  font-weight: normal;
  color: ${({ color }) => getThemeColor([color])};
  font-variant-ligatures: none;
  font-size: ${R.prop('size')}px;
  text-align: ${R.prop('align')};
  margin: ${R.prop('margin')};
  line-height: ${R.propOr('normal', 'lineHeight')};
`;

Text.defaultProps = {
  color: 'primaryRegular',
};

export const SubTitleText = styled(Text)`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
`;

export const ErrorText = styled(Text)`
  color: ${getThemeColor(['error'])};
  margin-bottom: 15px;
  font-size: 14px;
`;

export const DarkText = styled.p`
  font-family: ${getThemeFont(['defaultFont'])};
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${getThemeColor(['primaryDark'])};
`;

export const LabelText = styled(DarkText)`
  color: ${getThemeColor(['primaryLight2'])};
`;
