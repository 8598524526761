import { useEffect } from 'react';

import { useSearchProperties } from '../../components/PropertySelect.js';
import { useAssetTypes } from '../../components/AssetTypeSelect.js';
import { useAssetsMakes } from '../../components/AssetMakeSelect.js';

export const useCacheDataForOffline = (
  { hasPermissionToSubmitAsset },
  isAllowedTab,
) => {
  useSearchProperties(!hasPermissionToSubmitAsset);

  useAssetTypes(!hasPermissionToSubmitAsset);

  useAssetsMakes('', !hasPermissionToSubmitAsset);

  useEffect(() => {
    if (hasPermissionToSubmitAsset && isAllowedTab) {
      const loadAssetModelsWorker = new Worker(
        new URL('./loadAssetModelsWorker.js', import.meta.url),
      );

      loadAssetModelsWorker.postMessage({});

      loadAssetModelsWorker.onmessage = () => {
        loadAssetModelsWorker.terminate();
      };
    }
  }, [hasPermissionToSubmitAsset, isAllowedTab]);
};
