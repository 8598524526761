const IDB_STORE_CHANGE_EVENT = 'iDbStoreChanged';

export const publishIdbStoreChangedEvent = () => {
  const event = new CustomEvent(IDB_STORE_CHANGE_EVENT, {});
  document.dispatchEvent(event);
};

export const subscribeIdbStoreChange = (cb) => {
  document.addEventListener(IDB_STORE_CHANGE_EVENT, cb);
};

export const unsubscribeIdbStoreChange = (cb) => {
  document.removeEventListener(IDB_STORE_CHANGE_EVENT, cb);
};
