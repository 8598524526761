import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AssetsList } from './AssetsList.js';
import { Loader } from '../../components/Loader.js';
import { PropertiesList } from './PropertiesList.js';
import { NoResultsFoundScreen } from '../NoResultsFoundScreen.js';
import { PropertySortingOptions } from '../../constants/sorting.js';
import { ScreenListWrapper } from '../../components/ScreenListWrapper/ScreenListWrapper.js';
import { useSearchProperties } from './useSearchProperties.js';
import { useSortLogic } from '../../hooks/useSortLogic.js';

export function HomeScreen() {
  const isHistoryOpen = useSelector((state) => state.searchHistory.isOpen);
  const { sort } = useSortLogic(PropertySortingOptions[0].value);

  const { loading, total } = useSearchProperties('', sort);

  const isSingle = useMemo(() => total === 1, [total]);

  const List = useMemo(() => {
    if (total === 0 && !isHistoryOpen) return NoResultsFoundScreen;

    if (isSingle) return AssetsList;

    return PropertiesList;
  }, [loading, isSingle, total]);

  if (loading) return <Loader />;

  const screenProps = {
    List,
    entity: isSingle ? 'asset' : 'property',
    title: isSingle ? 'Your Assets' : 'Your Properties',
    globalClientSelect: true,
  };

  return <ScreenListWrapper {...screenProps} />;
}
