import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { string, func, bool } from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  entityToOptionByLabelPath,
  useCurrentUserQuery,
  useReactiveQuery,
  keywordSortQuery,
} from 'poly-client-utils';
import {
  ELASTIC_RESULT_WINDOW_MAX_SIZE,
  propertyStatuses,
  ASC_SORT_ORDER,
} from 'poly-constants';

import { Select } from './Select/Select.js';

const SEARCH_PROPERTIES_QUERY = gql`
  query SEARCH_PROPERTIES_QUERY($input: CollectionSearchParams!) {
    searchProperties(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

const SEARCH_PROPERTIES_SUBSCRIPTION = gql`
  subscription SEARCH_PROPERTIES_SUBSCRIPTION($input: CollectionSearchParams!) {
    searchPropertyChanged(input: $input) {
      id
      type
    }
  }
`;

const DEFAULT_PROPERTY_QUERY = gql`
  query DEFAULT_PROPERTY_QUERY($id: ID!) {
    property(id: $id) {
      _id
      name
    }
  }
`;

const USER_CHANGED = gql`
  subscription USER_CHANGED($input: SingleDocSubInput!) {
    userChanged(input: $input) {
      id
      type
      document {
        _id
      }
    }
  }
`;

// getSingleProperty :: PropertyQueryResult -> Property
const getSingleProperty = R.compose(
  R.pick(['_id', 'name']),
  R.propOr({}, 'property'),
);

// preparePropertyOptions :: PropertyQueryResult -> SearchPropertiesQueryResult -> [Option]
const preparePropertyOptions = (defaultData) =>
  R.compose(
    R.uniq,
    R.map(entityToOptionByLabelPath(['name'])),
    R.unless(
      () => R.propSatisfies(R.isNil, 'property')(defaultData),
      R.prepend(getSingleProperty(defaultData)),
    ),
    R.pathOr([], ['searchProperties', 'hits']),
  );

// isSingleProperty :: SearchPropertiesQueryResult -> Boolean
const isSingleProperty = R.pathSatisfies(R.equals(1), [
  'searchProperties',
  'hits',
  'length',
]);

export const getSearchPropertiesQuery = (clientId) => ({
  bool: {
    must: [
      { term: { status: propertyStatuses.ACTIVE } },
      ...(clientId ? [{ term: { clientId } }] : []),
    ],
  },
});

export const useSearchProperties = (skip = false) => {
  const { user } = useCurrentUserQuery();

  const clientId = useSelector((state) => state.globalClient?.clientId);

  const query = getSearchPropertiesQuery(clientId);

  const options = useMemo(
    () => ({
      variables: {
        input: {
          size: ELASTIC_RESULT_WINDOW_MAX_SIZE,
          sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
          query,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip,
    }),
    [skip],
  );

  const { data, loading } = useReactiveQuery(
    SEARCH_PROPERTIES_QUERY,
    [SEARCH_PROPERTIES_SUBSCRIPTION, USER_CHANGED],
    {
      queryOptions: options,
      subscriptionOptions: [
        options,
        { variables: { input: { id: user?._id } } },
      ],
    },
  );

  return { data, loading };
};

export function PropertySelect({
  value,
  onChange,
  hasError,
  required,
  disableIfSingle,
}) {
  const { data: searchPropertiesData, loading: searchPropertiesLoading } =
    useSearchProperties();

  const { data: defaultPropertyData, loading: defaultPropertyLoading } =
    useQuery(DEFAULT_PROPERTY_QUERY, {
      fetchPolicy: 'cache-and-network',
      variables: { id: value },
      skip: !value,
    });

  const options =
    preparePropertyOptions(defaultPropertyData)(searchPropertiesData);

  const isDisabled =
    !!disableIfSingle && isSingleProperty(searchPropertiesData);

  useEffect(() => {
    if (isDisabled && !value && options.length > 0) {
      onChange(options[0].value);
    }
  }, [isDisabled, value]);

  return (
    <Select
      value={value}
      options={options}
      hasError={hasError}
      onChange={onChange}
      isClearable={!required}
      isDisabled={isDisabled}
      placeholder="Select Property"
      isLoading={searchPropertiesLoading || defaultPropertyLoading}
    />
  );
}

PropertySelect.propTypes = {
  value: string,
  hasError: bool,
  required: bool,
  disableIfSingle: bool,
  onChange: func.isRequired,
};
