import * as R from 'ramda';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import React, { useEffect, useState, useMemo } from 'react';
import { number, string, shape, func, bool } from 'prop-types';
import { useNavigate, useRouterQuery } from 'poly-client-routing';
import { ExecutedProcedureStatuses } from 'poly-constants';
import { insertQueryParamsIntoURL } from 'poly-utils';

import { routes } from '../../routes.js';
import { Button } from '../../components/Button.js';
import { Loader } from '../../components/Loader.js';
import { TextArea } from '../../components/TextArea.js';
import { Redirect } from '../../components/Navigation.js';
import { FormFieldWithLabel } from '../../components/FormFieldWithLabel.js';
import { assetScannerIndexedDb } from '../../offline/indexedDb/indexedDb.js';
import { MultipleFilesUpload } from '../../components/MultipleFilesUpload.js';
import { ScreenHeaderS } from '../ProjectDetailsScreen/ProjectDetailsScreen.js';
import { attachPhotoFormValidators } from '../../components/AttachPhotoInput.js';
import { assetProceduresStoreName } from '../../offline/indexedDb/indexedDbStores.js';
import { CachedEntitySyncStatuses } from '../../offline/cache/helpers.js';
import { useNetworkStatus } from '../../providers/NetworkStatusProvider.js';
import { publishIdbStoreChangedEvent } from '../../offline/indexedDb/indexedDbStoreChangeEvent.js';
import {
  executeProcedureMutation,
  prepareExecutedProcedureInput,
} from './checkInHelpers.js';
import { useModalContext } from '../../providers/ModalProvider.js';
import { ErrorModal } from '../EditAssetQueueScreen/ErrorModal.js';

const ProcedureStepsWrapperS = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  justify-content: space-between;
`;

const ProcedureStepsNavigatorS = styled.div`
  display: flex;
  width: 100%;
  height: 4px;
  flex-direction: row;
  justify-content: space-between;
`;

const ProcedureStepsNavTabS = styled.div`
  display: flex;
  height: 4px;
  width: ${({ stepsCount }) => `calc(100%/${stepsCount} - 2px)`};
  background-color: ${({ isActive }) =>
    isActive ? '#00dedc' : 'rgba(30, 36, 44, 0.22)'};
`;

const ProcedureStepBlockWrapperS = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const ProcedureStepFormTextS = styled.div`
  display: flex;
  font-size: ${R.prop('size')}px;
  color: #12347a;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
`;

const FormS = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
`;

// getAssetProcedureSteps :: { Procedure } -> [ProcedureStep]
const getAssetProcedureSteps = R.propOr([], 'steps');

// getProcedureStepsCountArr :: Number -> [Number]
const getProcedureStepsCountArr = R.times(R.identity);

// getActiveStepContent :: Number -> [ProcedureStep] -> ProcedureStep
const getActiveStepContent = (activeStep) =>
  R.compose(R.defaultTo({}), R.nth(activeStep));

// prepareInitialValues :: ProcedureStep -> ProcedureStep
const prepareInitialValues = R.compose(
  R.assoc('status', ExecutedProcedureStatuses.COMPLETED),
  R.over(R.lensProp('attachments'), R.defaultTo([])),
);

// prepareUpdatedSteps :: (ProcedureStep, Number) -> [ProcedureStep] -> [ProcedureStep]
const prepareUpdatedSteps = (updatedStep, stepCount) =>
  R.over(R.lensIndex(stepCount), R.always(updatedStep));

const saveProcedureAsReadyToSynchP = async (
  iDb,
  assetProcedure,
  procedureId,
) => {
  await iDb.update(
    assetProceduresStoreName,
    {
      ...assetProcedure,
      syncStatus: CachedEntitySyncStatuses.PENDING,
      createdAt: new Date(),
    },
    procedureId,
  );
};

function ProcedureStepsNavigator({ stepsCount, activeStepCount }) {
  const procedureStepsCountArr = getProcedureStepsCountArr(stepsCount);

  return (
    <ProcedureStepsNavigatorS>
      {procedureStepsCountArr.map((step) => (
        <ProcedureStepsNavTabS
          key={step}
          stepsCount={stepsCount}
          isActive={activeStepCount === step}
        />
      ))}
    </ProcedureStepsNavigatorS>
  );
}

ProcedureStepsNavigator.propTypes = {
  stepsCount: number.isRequired,
  activeStepCount: number.isRequired,
};

function ProcedureStepFormC({ handleSubmit, values, formId }) {
  const { isImageUploadRequired, isRequiredStep } = values;

  return (
    <FormS id={formId} onSubmit={handleSubmit}>
      <FormFieldWithLabel
        name="comment"
        Component={TextArea}
        required={isRequiredStep}
        validators={isRequiredStep ? [[R.identity, 'Comment is required']] : []}
      />
      <FormFieldWithLabel
        name="attachments"
        Component={MultipleFilesUpload}
        validators={[
          ...attachPhotoFormValidators(R.all),
          ...(isImageUploadRequired
            ? [[R.complement(R.isEmpty), 'Attachments are required']]
            : []),
        ]}
      />
    </FormS>
  );
}

ProcedureStepFormC.propTypes = {
  formId: string.isRequired,
  handleSubmit: func.isRequired,
  values: shape({ isRequiredStep: bool, isImageUploadRequired: bool })
    .isRequired,
};

function ProcedureStepForm({ formId, activeStep, handleProcedureStep }) {
  return (
    <Form
      formId={formId}
      render={ProcedureStepFormC}
      onSubmit={handleProcedureStep}
      initialValues={prepareInitialValues(activeStep)}
    />
  );
}

ProcedureStepForm.propTypes = {
  formId: string.isRequired,
  handleProcedureStep: func.isRequired,
  activeStep: shape({ comment: string }).isRequired,
};

const executeProcedureErrorModalId = 'executeProcedureErrorModalId';

export function ProcedureStepsScreen() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isInProgress, setIsInProgress] = useState(false);
  const [assetProcedure, setAssetProcedure] = useState({});
  const [activeStepCount, setActiveStepCount] = useState(0);
  const [executeProcedure] = useMutation(executeProcedureMutation);
  const [assetProcedureSteps, setAssetProcedureSteps] = useState([]);
  const { isOnline } = useNetworkStatus();
  const { openModal, closeModal } = useModalContext();

  const { maintenanceProcedureId } = useRouterQuery(['maintenanceProcedureId']);

  const handleClose = () => closeModal(executeProcedureErrorModalId);

  useEffect(() => {
    const getAssetProcedure = async () => {
      const iDb = await assetScannerIndexedDb();

      const cashedAssetProcedure = await iDb.getById(
        assetProceduresStoreName,
        maintenanceProcedureId,
      );

      const cashedAssetProcedureSteps =
        getAssetProcedureSteps(cashedAssetProcedure);

      setAssetProcedure(cashedAssetProcedure);

      setAssetProcedureSteps(cashedAssetProcedureSteps);

      setLoading(false);
    };

    getAssetProcedure();
  }, []);

  const activeStep = useMemo(
    () => getActiveStepContent(activeStepCount)(assetProcedureSteps),
    [assetProcedureSteps, activeStepCount],
  );

  const isLastStep = assetProcedureSteps.length === activeStepCount + 1;

  const handleProcedureStep = async (step) => {
    try {
      setIsInProgress(true);

      const iDb = await assetScannerIndexedDb();

      const updatedProcedureSteps = prepareUpdatedSteps(
        step,
        activeStepCount,
      )(assetProcedureSteps);

      if (isLastStep) {
        if (isOnline) {
          const input = prepareExecutedProcedureInput({
            ...assetProcedure,
            steps: updatedProcedureSteps,
          });

          await executeProcedure({ variables: { input } });

          await iDb.deleteById(
            assetProceduresStoreName,
            maintenanceProcedureId,
          );
        } else {
          await saveProcedureAsReadyToSynchP(
            iDb,
            {
              ...assetProcedure,
              steps: updatedProcedureSteps,
            },
            maintenanceProcedureId,
          );
          publishIdbStoreChangedEvent();
        }

        setIsInProgress(false);

        navigate(
          insertQueryParamsIntoURL(
            {
              isProcedureExecuted: true,
              projectId: assetProcedure.projectId,
            },
            routes.checkInSuccess,
          ),
        );
      } else {
        await iDb.update(
          assetProceduresStoreName,
          {
            ...assetProcedure,
            isServicingStarted: true,
            steps: updatedProcedureSteps,
          },
          maintenanceProcedureId,
        );

        setAssetProcedureSteps(updatedProcedureSteps);

        setActiveStepCount(activeStepCount + 1);
      }
    } catch (error) {
      openModal({
        id: executeProcedureErrorModalId,
        content: (
          <ErrorModal errorMsg={error.message} handleClose={handleClose} />
        ),
      });
    } finally {
      setIsInProgress(false);
    }
  };

  if (!loading && assetProcedureSteps === 0) {
    return <Redirect route={routes.notFoundProject} />;
  }

  const { description, isRequiredStep, name } = activeStep;

  const formId = `complete-procedure-step-${activeStepCount}-form-id`;

  return (
    <>
      <ScreenHeaderS
        goBack
        title="Maintenance Procedure List"
        onClickBack={
          activeStepCount === 0
            ? null
            : () => setActiveStepCount(activeStepCount - 1)
        }
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <ProcedureStepsNavigator
            activeStepCount={activeStepCount}
            stepsCount={assetProcedureSteps.length}
          />
          <ProcedureStepsWrapperS>
            <ProcedureStepBlockWrapperS>
              <ProcedureStepFormTextS size={20}>{name}</ProcedureStepFormTextS>
              <ProcedureStepFormTextS size={14}>
                {description}
              </ProcedureStepFormTextS>
              <ProcedureStepForm
                key={formId}
                formId={formId}
                activeStep={activeStep}
                activeStepCount={activeStepCount}
                handleProcedureStep={handleProcedureStep}
              />
            </ProcedureStepBlockWrapperS>
            <ProcedureStepBlockWrapperS>
              {!isRequiredStep && (
                <Button
                  inverted
                  type="button"
                  onClick={() =>
                    handleProcedureStep({
                      ...activeStep,
                      status: ExecutedProcedureStatuses.SKIPPED,
                    })
                  }
                  caption={isLastStep ? 'Skip & Complete' : 'Skip'}
                />
              )}
              <Button
                type="submit"
                form={formId}
                loading={isInProgress}
                caption={isLastStep ? 'Complete & Check-In' : 'Next'}
              />
            </ProcedureStepBlockWrapperS>
          </ProcedureStepsWrapperS>
        </>
      )}
    </>
  );
}
