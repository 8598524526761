import React from 'react';
import { string } from 'prop-types';

import {
  StaticHolderTextS,
  StaticHolderTitleS,
  StaticHolderWrapperS,
} from '../components/StaticHolder.js';
import { Icon } from '../icons/Icon.js';

export function EmptyAssetsListScreen({ className }) {
  return (
    <StaticHolderWrapperS className={className}>
      <Icon name="emptyBox" />
      <StaticHolderTitleS>No Assets</StaticHolderTitleS>
      <StaticHolderTextS>There are no assets yet!</StaticHolderTextS>
    </StaticHolderWrapperS>
  );
}

EmptyAssetsListScreen.propTypes = { className: string };
