import styled from 'styled-components';
import React from 'react';
import { func, node, number, string } from 'prop-types';
import { Icon } from '../../icons/Icon.js';

export const FormS = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
`;

export const FormWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  height: ${({ footerHeight }) =>
    footerHeight && `calc(100% - ${footerHeight}px)`};
  padding: 24px;
`;

const FormTitleS = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  color: #12347a;
  margin: 20px 0 15px 0;
`;

export function FormContainer({ children, title, footerHeight, onClose }) {
  return (
    <FormWrapperS footerHeight={footerHeight}>
      <Icon name="close" onClick={onClose} />
      <FormTitleS>{title}</FormTitleS>
      {children}
    </FormWrapperS>
  );
}

FormContainer.propTypes = {
  onClose: func.isRequired,
  title: string.isRequired,
  children: node.isRequired,
  footerHeight: number,
};

export const FormFooterContainer = styled.div`
  display: flex;
  width: calc(100% - 48px);
  height: 64px;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0px -4px 26px rgba(0, 0, 0, 0.15);
`;
