import React from 'react';
import { string } from 'prop-types';
import { icons } from './icons.js';

export function Icon({ name, ...props }) {
  const IconC = icons[name];
  return <IconC {...props} />;
}

Icon.propTypes = {
  name: string.isRequired,
};
