import { useState, useCallback } from 'react';
import { debounce } from 'poly-utils';

export const useSearchLogic = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const onSearchDebounced = useCallback(
    debounce(400)((searchText) => {
      setSearchTerm(searchText);
    }),
    [],
  );

  return { searchTerm, onSearch: onSearchDebounced };
};
