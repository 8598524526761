import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';

import { AuthRequired } from '../components/AuthRequired.js';
import {
  SyncAssetsQueueStatus,
  useSyncAssetsQueueStatusHeight,
} from '../offline/SyncAssetsQueueStatus.js';

const CommonLayoutContainer = styled.div`
  width: 100%;
  height: ${({ syncCompHeight }) =>
    syncCompHeight ? `calc(100% - ${syncCompHeight}px)` : '100%'};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export function BaseLayout({ children }) {
  const syncCompHeight = useSyncAssetsQueueStatusHeight();
  return (
    <AuthRequired>
      <SyncAssetsQueueStatus />
      <CommonLayoutContainer syncCompHeight={syncCompHeight}>
        <ContentContainer>{children}</ContentContainer>
      </CommonLayoutContainer>
    </AuthRequired>
  );
}

BaseLayout.propTypes = {
  children: node.isRequired,
};
