import * as R from 'ramda';

// Note: DO NOT CHANGE. Physical QR codes point to this route
const ASSET_SCANNER_SUBMIT_PROJECT_PATH = '/a';

// getAssetScannerSubmitProjectPathByQrCodeId :: String -> String
export const getAssetScannerSubmitProjectPathByQrCodeId = R.compose(
  R.concat(ASSET_SCANNER_SUBMIT_PROJECT_PATH),
  R.concat('/'),
  R.defaultTo(''),
);
