import React from 'react';
import { useNavigate, useLocation } from 'poly-client-routing';

import {
  FlexColumnCentered,
  FlexColumnSpaceBetween,
} from '../../components/Containers.js';
import { routes } from '../../routes.js';
import { Text } from '../../components/Text.js';
import { SuccessScreen } from '../StatusScreen.js';
import { Button, GoHomeBtn } from '../../components/Button.js';
import { useNetworkStatus } from '../../providers/NetworkStatusProvider.js';

function AddBtn() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const onClick = () => navigate(routes.scanQrCode + search);

  return (
    <Button caption="Add Another Asset" iconName="plus" onClick={onClick} />
  );
}

export function AddAssetSuccessScreen() {
  const { isOnline } = useNetworkStatus();

  const successScreenTitle = isOnline ? 'Asset Added' : 'Asset Queued';
  const successScreenText = isOnline
    ? 'Your asset has been submitted and will be reviewed by our team.'
    : 'Your asset has been added to the queue and will be submitted once you reconnect to the internet.';

  return (
    <SuccessScreen>
      <FlexColumnSpaceBetween>
        <FlexColumnCentered>
          <Text size={22} color="primaryDark">
            {successScreenTitle}
          </Text>
          <Text size={14} color="mainDark" margin="12px 0">
            {successScreenText}
          </Text>
        </FlexColumnCentered>
        <FlexColumnCentered>
          <AddBtn />
          <GoHomeBtn />
        </FlexColumnCentered>
      </FlexColumnSpaceBetween>
    </SuccessScreen>
  );
}
