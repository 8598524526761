import React from 'react';
import styled from 'styled-components';
import { Text } from '../components/Text.js';
import { FlexColumnCentered } from '../components/Containers.js';
import { ContactPhoneLink } from '../components/PhoneLink.js';
import { LogoHeader } from '../components/LogoHeader.js';

const Container = styled(FlexColumnCentered)`
  flex-grow: 1;
  padding: 60px;
  justify-content: center;
`;

const TextContainer = styled(FlexColumnCentered)`
  span {
    margin-bottom: 12px;
    text-align: center;
  }
`;

export function NotFoundProjectScreen() {
  return (
    <>
      <LogoHeader />
      <Container>
        <TextContainer>
          <Text size={22}>There is no project yet</Text>
          <Text size={12}>
            If you have new information or need additional service on this
            equipment please call us at
          </Text>
          <ContactPhoneLink />
        </TextContainer>
      </Container>
    </>
  );
}
