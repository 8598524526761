import React from 'react';
import { node } from 'prop-types';
import styled from 'styled-components';

import { Navigation } from '../components/Navigation.js';
import { AuthRequired } from '../components/AuthRequired.js';
import {
  SyncAssetsQueueStatus,
  useSyncAssetsQueueStatusHeight,
} from '../offline/SyncAssetsQueueStatus.js';
import { useGlobalClient } from './useGlobalClient.js';

export const LayoutContainer = styled.div`
  width: 100%;
  height: ${({ syncCompHeight }) =>
    syncCompHeight ? `calc(100% - ${syncCompHeight}px)` : '100%'};
  box-sizing: border-box;
  background: #f6f6f6;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 68px);
  box-sizing: border-box;
`;

export function NavigationLayout({ children }) {
  const syncCompHeight = useSyncAssetsQueueStatusHeight();
  useGlobalClient();

  return (
    <AuthRequired>
      <SyncAssetsQueueStatus />
      <LayoutContainer syncCompHeight={syncCompHeight}>
        <ContentContainer>{children}</ContentContainer>
        <Navigation />
      </LayoutContainer>
    </AuthRequired>
  );
}

NavigationLayout.propTypes = {
  children: node.isRequired,
};
