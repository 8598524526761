import React from 'react';
import { func, string } from 'prop-types';
import * as R from 'ramda';
import { useInternationalPhoneLogic } from 'poly-client-utils';

import { Input } from '../Input.js';

export function PhoneNumberInput({ value, onChange, onFocus, ...props }) {
  const inputProps = useInternationalPhoneLogic({ value, onChange, onFocus });

  return <Input {...props} {...inputProps} />;
}

PhoneNumberInput.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  onFocus: func,
};

PhoneNumberInput.defaultProps = {
  onFocus: R.T,
};
