import * as R from 'ramda';
import { useState, useEffect } from 'react';

// generateQueryInput :: (Sorting, String, ElasticQuery) -> CollectionSearchParams
const generateQueryInput = (sort, searchTerm, query) => ({
  sort,
  query,
  size: 25,
  searchTerm,
});

export const useStateInputForInfiniteListQuery = (sort, searchTerm, query) => {
  const [queryInput, setQueryInput] = useState(
    generateQueryInput(sort, searchTerm, query),
  );

  useEffect(() => {
    const updatedQueryInput = generateQueryInput(sort, searchTerm, query);

    if (!R.equals(queryInput, updatedQueryInput)) {
      setQueryInput(updatedQueryInput);
    }
  }, [searchTerm, sort]);

  return queryInput;
};
