import { ProjectSortingOptions } from '../../constants/sorting.js';
import { SET_PROJECT_DESCRIPTION, SET_PROJECTS_FILTER } from './actions.js';

export const projectReducer = (state = {}, action = {}) => {
  if (action.type === SET_PROJECT_DESCRIPTION) {
    return { ...state, description: action.payload };
  }
  return { ...state };
};

export const defaultProjectsFilterState = {
  status: null,
  priority: null,
  propertyId: null,
  sortingOption: ProjectSortingOptions[0].value,
};

export const searchProjectsFilterReducer = (state, action) => {
  if (action?.type === SET_PROJECTS_FILTER) {
    return { ...state, ...action.payload };
  }

  return state || defaultProjectsFilterState;
};
