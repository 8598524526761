import React from 'react';
import * as R from 'ramda';
import { format } from 'date-fns';
import styled from 'styled-components';
import { string, func, instanceOf, oneOfType, bool } from 'prop-types';
import { formatDate, ensureIsDate, isNilOrEmpty } from 'poly-utils';

import { Icon } from '../icons/Icon.js';
import { inputStyles } from './Input.js';

const DatePickerWrapperS = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const DatePickerInputS = styled.input`
  ${inputStyles};

  height: 45px;
  width: 100%;
  z-index: 1;
  background: transparent;
  color: transparent;

  ::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  ::-webkit-datetime-edit-year-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-day-field {
    display: none;
  }
`;

const ClearIconS = styled(Icon)`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 17px;
  right: 20px;
  z-index: 2;
`;

const DatePickerPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${inputStyles};
`;

// formatInputValue :: Date -> String
const formatInputValue = R.compose(
  R.when(R.is(Date), (date) => format(date, 'yyyy-MM-dd')),
  ensureIsDate,
);

// prepareValueOnChange :: Event -> Date
const prepareValueOnChange = R.compose(
  R.unless(isNilOrEmpty, ensureIsDate),
  R.pathOr('', ['target', 'value']),
);

export function DatePicker({
  value,
  onChange,
  className,
  isClearable = true,
  ...props
}) {
  const onChangeDate = R.compose(onChange, prepareValueOnChange);

  const onClear = () => onChange(null);

  const showClearIcon = !!isClearable && !isNilOrEmpty(value);

  return (
    <DatePickerWrapperS className={className}>
      <DatePickerInputS
        {...props}
        type="date"
        name="DatePickerInput"
        onChange={onChangeDate}
        placeholder=""
        value={value && formatInputValue(value)}
      />
      <DatePickerPlaceholder>
        {value && formatDate(value)}
      </DatePickerPlaceholder>
      {showClearIcon && <ClearIconS name="close" onClick={onClear} />}
    </DatePickerWrapperS>
  );
}

DatePicker.propTypes = {
  className: string,
  isClearable: bool,
  onChange: func.isRequired,
  value: oneOfType([string, instanceOf(Date)]),
};
