import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';

import { Icon } from '../icons/Icon.js';

const NoImageHolderWrapperS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  flex-shrink: 0;

  width: ${R.prop('width')};
  height: ${R.prop('height')};
`;

export function NoImageHolder({ width = '100%', height = '100%' }) {
  return (
    <NoImageHolderWrapperS {...{ width, height }}>
      <Icon name="noImage" width="30%" height="100%" />
    </NoImageHolderWrapperS>
  );
}

NoImageHolder.propTypes = { width: string, height: string };
