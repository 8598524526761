import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { string, bool, func, oneOfType, objectOf, element } from 'prop-types';

import {
  AssetSortingOptions,
  ProjectSortingOptions,
  PropertySortingOptions,
} from '../../constants/sorting.js';
import { SearchInput } from './SearchInput.js';
import { ScreenHeader } from '../ScreenHeader.js';
import { SortingWithTotal } from './SortingWithTotal.js';
import { SearchHistoryList } from '../SearchHistoryList.js';
import { useHistoryCacheByEntity } from '../../hooks/useHistoryCacheByEntity.js';
import { useComponentHeightById } from '../../hooks/useComponentHeightById.js';
import { SET_LIST_SCROLL } from '../../redux/listScroll/actions.js';
import { useSearchLogic } from '../../hooks/useSearchLogic.js';
import { useSortLogic } from '../../hooks/useSortLogic.js';
import { GlobalClientSelect } from '../GlobalClientSelect.js';

const SEARCH_INPUT_HEIGHT = 78;
const SORT_SELECTOR_HEIGHT = 49;

// change these when refactor components for the correct UI work
const ACTIVE_SECTION_HEIGHT = SEARCH_INPUT_HEIGHT + SORT_SELECTOR_HEIGHT;

const ScreenHeaderS = styled(ScreenHeader)`
  padding-bottom: 0;
`;

const ActiveHeaderSectionS = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  transition-property: margin-top;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;

  height: ${({ showHistory }) =>
    showHistory ? SEARCH_INPUT_HEIGHT : ACTIVE_SECTION_HEIGHT}px;
  margin-top: ${({ direction }) =>
    direction === 'forward' ? `-${ACTIVE_SECTION_HEIGHT + 3}px` : 0};
`;

const ContentWrapperS = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100% - ${R.prop('componentHeight')}px);
  overflow: hidden;
`;

const ListWrapperS = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 24px 0 24px;
`;

// getSortOptionsByEntity :: String -> [Option]
const getSortOptionsByEntity = R.cond([
  [R.equals('property'), R.always(PropertySortingOptions)],
  [R.equals('project'), R.always(ProjectSortingOptions)],
  [R.equals('asset'), R.always(AssetSortingOptions)],
  [R.T, R.always([])],
]);

// getEntityNameByEntity :: String -> String
const getEntityNameByEntity = R.cond([
  [R.equals('property'), R.always('Properties')],
  [R.equals('project'), R.always('Projects')],
  [R.equals('asset'), R.always('Assets')],
  [R.T, R.always('Unknown')],
]);

// getTotalWithEntityName :: (Int, String) -> String
const getTotalWithEntityName = (total, entity) =>
  `${total} ${getEntityNameByEntity(entity)}`;

export function ScreenListWrapper({
  List,
  route,
  title,
  goBack,
  entity,
  Toolbar,
  onSortHandler,
  globalClientSelect,
  listProps = {},
}) {
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const { onSearch, searchTerm } = useSearchLogic();
  const { getHistoryRecords } = useHistoryCacheByEntity();
  const direction = useSelector((state) => state.listScroll.direction);
  const isHistoryOpen = useSelector(R.path(['searchHistory', 'isOpen']));
  const { componentHeight } = useComponentHeightById('screen-header', 80);

  const options = getSortOptionsByEntity(entity);
  const totalTitle = getTotalWithEntityName(total, entity);
  const historyNotEmpty = R.complement(R.isEmpty)(getHistoryRecords(entity));

  const { sort, setSortingOption, ...sortingProps } = useSortLogic(
    options[0].value,
  );

  useEffect(
    () => () => {
      dispatch({
        type: SET_LIST_SCROLL,
        payload: null,
      });
    },
    [],
  );

  const handleSorting = (option) => {
    setSortingOption(option);
    if (!!onSortHandler && R.is(Function, onSortHandler)) {
      onSortHandler(option);
    }
  };

  const showHistory = isHistoryOpen && historyNotEmpty;

  return (
    <>
      <ScreenHeaderS {...{ title, route, goBack }} />
      {globalClientSelect && <GlobalClientSelect />}
      <ContentWrapperS componentHeight={componentHeight}>
        <ActiveHeaderSectionS direction={direction} showHistory={showHistory}>
          <SearchInput onChange={onSearch} entity={entity} Toolbar={Toolbar} />
          {total !== 0 && !showHistory ? (
            <SortingWithTotal
              {...sortingProps}
              options={options}
              totalTitle={totalTitle}
              setSortingOption={handleSorting}
            />
          ) : null}
        </ActiveHeaderSectionS>
        {showHistory ? (
          <SearchHistoryList entity={entity} />
        ) : (
          <ListWrapperS>
            <List {...{ sort, searchTerm, setTotal, entity, ...listProps }} />
          </ListWrapperS>
        )}
      </ContentWrapperS>
    </>
  );
}

ScreenListWrapper.propTypes = {
  goBack: bool,
  route: string,
  Toolbar: element,
  onSortHandler: func,
  List: func.isRequired,
  title: string.isRequired,
  entity: string.isRequired,
  listProps: objectOf(oneOfType([string, bool, func])),
  globalClientSelect: bool,
};
