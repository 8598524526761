import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';

import { Icon } from '../icons/Icon.js';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 24px;
  gap: 10px;
`;

const IconWrapper = styled.div`
  width: 33px;
  height: 33px;
`;

const ContentWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: calc(100% - 40px);
  word-break: break-all;
`;

export function LogoHeader({ children }) {
  return (
    <HeaderContainer>
      <IconWrapper>
        <Icon name="logo" width={33} height={33} />
      </IconWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </HeaderContainer>
  );
}

LogoHeader.propTypes = {
  children: node,
};
