import { SET_GLOBAL_CLIENT } from './actions.js';

const defaultState = { clientId: null };

export const globalClientReducer = (state, action) => {
  if (action?.type === SET_GLOBAL_CLIENT) {
    return action.payload ? { ...state, ...action.payload } : defaultState;
  }

  return state || defaultState;
};
