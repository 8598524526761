import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'poly-client-routing';

const assetWithCheckInProjectsQuery = gql`
  query assetWithCheckInProjectsQuery($input: AssetByQrCodeIdInput!) {
    assetByQrCodeId(input: $input) {
      _id
      type {
        _id
        name
      }
      qrCodeId
      photo {
        url
      }
      checkInProjects {
        projectId
        suppliers {
          supplierId
          supplierName
        }
        assetProcedures {
          asset {
            _id
          }
          procedure {
            _id
            steps {
              name
              description
              isRequiredStep
              isImageUploadRequired
            }
          }
          executedProcedure {
            _id
          }
        }
      }
    }
  }
`;

// getAsset :: ApolloQueryResult -> Asset
const getAsset = R.prop('assetByQrCodeId');

export const useAssetCheckInProjectsQuery = () => {
  const { assetQrCodeId } = useParams();

  const { data, loading } = useQuery(assetWithCheckInProjectsQuery, {
    variables: { input: { qrCodeId: assetQrCodeId } },
    fetchPolicy: 'cache-and-network',
  });

  const asset = getAsset(data);

  return { asset, loading };
};
