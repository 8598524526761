import React, { useEffect } from 'react';

import { Input } from './Input.js';

export function InputSmsCode(props) {
  useEffect(() => {
    if ('OTPCredential' in window) {
      const input = document.querySelector(
        'input[autocomplete="one-time-code"]',
      );
      const ac = new AbortController();

      const autoFill = async () => {
        try {
          const otp = await navigator.credentials.get({
            otp: { transport: ['sms'] },
            signal: ac.signal,
          });
          input.value = otp.code;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error while autofill: ', err);
        }
      };

      autoFill();
    }
  }, []);
  return <Input {...props} />;
}
