import React, { useState } from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { arrayOf, element, shape, string } from 'prop-types';
import { getThemeColor } from 'poly-book-admin';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabsHeader = styled.div`
  display: flex;
  width: 100%;
`;

const activeTabStyles = css`
  color: ${getThemeColor(['secondaryDark'])};
  border-bottom: 5px solid ${getThemeColor(['secondaryDark'])};
  padding-bottom: 9px;
`;

const TabItem = styled.div`
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 12px;
  border-bottom: 2px solid #c8c8c8;
  text-align: center;
  color: #575757;
  cursor: pointer;
  ${({ isActive }) => isActive && activeTabStyles}
`;

// isTabActive :: String -> Tab -> Boolean
const isTabActive = R.propEq('key');

// getTabContent :: String -> [Tab] -> ReactElement
const getTabContent = (key, tabs) =>
  R.compose(R.prop('content'), R.find(R.propEq('key', key)))(tabs);

export function Tabs({ tabs, activeTab, className }) {
  const [activeKey, setActiveKey] = useState(activeTab);

  const tabContent = getTabContent(activeKey, tabs);

  return (
    <Wrapper className={className}>
      <TabsHeader>
        {tabs.map((tab) => (
          <TabItem
            key={tab.key}
            onClick={() => setActiveKey(tab.key)}
            isActive={isTabActive(activeKey, tab)}
          >
            {tab.title}
          </TabItem>
        ))}
      </TabsHeader>
      {tabContent}
    </Wrapper>
  );
}

Tabs.propTypes = {
  className: string,
  activeTab: string.isRequired,
  tabs: arrayOf(
    shape({
      key: string.isRequired,
      title: string.isRequired,
      content: element,
    }),
  ),
};
