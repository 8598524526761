import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import { getThemeColor } from 'poly-book-admin';

import { Icon } from '../icons/Icon.js';
import { useHistoryCacheByEntity } from '../hooks/useHistoryCacheByEntity.js';
import { useSetInputValueById } from '../hooks/useSetInputValueById.js';
import { TransparentButton } from './TransparentButton.js';

const SearchHistoryListWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 22px 0;
  height: 100%;
`;

const SearchHistoryListHeaderS = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 48px);
  padding: 0 24px;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const SearchHistoryTitleS = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: ${getThemeColor(['primaryDark'])};
`;

const SearchHistoryTitleButtonS = styled.div`
  color: #cd1544;
  cursor: pointer;
`;

const SearchHistoryItemS = styled(SearchHistoryListHeaderS)`
  margin: 0;
  padding: 22px 24px;
  border-bottom: 2px solid ${getThemeColor(['midLight'])};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${getThemeColor(['primaryDark'])};
  align-items: center;

  > span {
    width: inherit;
    padding: 0 10px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

// removeListItem :: String -> [String] -> String
const removeListItem = (item) =>
  R.compose(R.reject(R.equals(item)), R.defaultTo([]));

export function SearchHistoryList({ entity }) {
  const { clearHistory, getHistoryRecords, removeHistoryRecord } =
    useHistoryCacheByEntity();

  const [localRecords, setLocalRecords] = useState(getHistoryRecords(entity));
  const setHistoryRecord = useSetInputValueById('search-input');

  const onHistoryItemRemove = (record) => (e) => {
    e.preventDefault();
    setLocalRecords(removeListItem(record)(localRecords));
    removeHistoryRecord(record, entity);
  };

  const onHistoryItemSelect = (record) => (e) => {
    e.preventDefault();
    setHistoryRecord(record);
  };

  const onHistoryClear = (e) => {
    e.preventDefault();
    setLocalRecords([]);
    clearHistory(entity);
  };

  return (
    <SearchHistoryListWrapperS>
      <SearchHistoryListHeaderS>
        <SearchHistoryTitleS>Your searches</SearchHistoryTitleS>
        <SearchHistoryTitleButtonS onMouseDown={onHistoryClear}>
          Clear
        </SearchHistoryTitleButtonS>
      </SearchHistoryListHeaderS>
      {localRecords.map((record) => (
        <SearchHistoryItemS key={record}>
          <FlexRow>
            <Icon width={16} name="clockHistory" color="#5E6271" />
            <TransparentButton
              tabIndex={0}
              onMouseDown={onHistoryItemSelect(record)}
            >
              {record}
            </TransparentButton>
          </FlexRow>
          <TransparentButton
            tabIndex={0}
            onMouseDown={onHistoryItemRemove(record)}
          >
            <Icon width="10" height="10" name="close" color="#9197AC" />
          </TransparentButton>
        </SearchHistoryItemS>
      ))}
    </SearchHistoryListWrapperS>
  );
}

SearchHistoryList.propTypes = { entity: string.isRequired };
