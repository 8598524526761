import React from 'react';
import { string, func } from 'prop-types';
import { WorkOrderPriority } from 'poly-constants';

import { Select } from './Select/Select.js';

const ProjectPriorityOptions = [
  { value: WorkOrderPriority.NORMAL, label: 'Normal' },
  { value: WorkOrderPriority.URGENT, label: 'Urgent' },
  { value: WorkOrderPriority.PLANNED, label: 'Planned' },
  { value: WorkOrderPriority.EMERGENCY, label: 'Emergency' },
];

export function ProjectPrioritySelect({ value, onChange }) {
  return (
    <Select
      isClearable
      value={value}
      onChange={onChange}
      placeholder="Select Priority"
      options={ProjectPriorityOptions}
    />
  );
}

ProjectPrioritySelect.propTypes = {
  value: string,
  onChange: func.isRequired,
};
