import { useEffect } from 'react';

export const useSetStatusBarColorOnMount = (color) => {
  const themeColorMetaTag = document.querySelector("meta[name='theme-color']");
  useEffect(() => {
    themeColorMetaTag.setAttribute('content', color);

    return () => themeColorMetaTag.setAttribute('content', '#f6f6f6');
  }, []);
};
