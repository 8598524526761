import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { bool, shape, string } from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { useLocation, useNavigate } from 'poly-client-routing';
import { UPDATE_ASSET_PERMISSION } from 'poly-security';
import { insertParamsIntoURL } from 'poly-utils';

import { routes } from '../../routes.js';
import { Icon } from '../../icons/Icon.js';
import { Loader } from '../../components/Loader.js';
import { DarkText } from '../../components/Text.js';
import { InfinityList } from '../../components/InfinityList.js';
import { AssetListCard } from '../../components/AssetListCard.js';
import { NoResultsFoundScreen } from '../NoResultsFoundScreen.js';
import { EmptyAssetsListScreen } from '../EmptyAssetsListScreen.js';
import { screenListPropTypes } from '../../components/ScreenListWrapper/screenListPropTypes.js';
import { useSetListTotal } from '../../hooks/useSetListTotal.js';
import { useSearchAssets } from './useSearchAssets.js';

const AssetItemWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  box-sizing: border-box;
  background: #ffffff;
`;

const AssetItemContentWrapperS = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
  gap: 16px;
`;

const AssetItemButtonsWrapperS = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  padding-top: 16px;

  > div {
    width: 100%;

    &:nth-child(2) {
      width: 120px;
      margin-right: 16px;
      flex-shrink: 0;
    }
  }
`;

export const IconButtonWrapperS = styled.div`
  display: flex;
  height: 42px;
  justify-content: center;
  align-items: center;
  background: #f6f9fd;
  cursor: pointer;
  opacity: ${({ disable }) => (disable ? '0.3' : '1')};

  > p {
    padding-left: 5px;

    @media screen and (max-width: 370px) {
      font-size: 12px;
    }
  }
`;

export const TextButton = styled(DarkText)`
  line-height: 18px;
`;

export function NavigateIconButton({ name, text, to, disable, ...props }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (!disable) {
      navigate(to, {
        state: { previous: location },
      });
    }
  };

  return (
    <IconButtonWrapperS onClick={handleClick} {...props} disable={disable}>
      <Icon name={name} />
      <TextButton>{text}</TextButton>
    </IconButtonWrapperS>
  );
}

NavigateIconButton.propTypes = {
  name: string.isRequired,
  text: string.isRequired,
  to: string.isRequired,
  disable: bool,
};

function AssetListItem({ asset }) {
  const hasAccess = useHasUserAccessWithPermission(UPDATE_ASSET_PERMISSION);

  const assetDetailUrl = insertParamsIntoURL(
    { assetId: asset._id },
    routes.assetDetail,
  );

  const editAssetUrl = insertParamsIntoURL(
    { assetId: asset._id },
    routes.editAsset,
  );

  return (
    <AssetItemWrapperS>
      <AssetItemContentWrapperS>
        <AssetListCard asset={asset} />
      </AssetItemContentWrapperS>
      <AssetItemButtonsWrapperS>
        <NavigateIconButton
          text="View Asset & History"
          name="history"
          to={assetDetailUrl}
        />
        {hasAccess && (
          <NavigateIconButton text="Edit Asset" name="edit" to={editAssetUrl} />
        )}
      </AssetItemButtonsWrapperS>
    </AssetItemWrapperS>
  );
}

AssetListItem.propTypes = {
  asset: shape({ _id: string.isRequired }).isRequired,
};

export function AssetsList({ propertyId, sort, searchTerm, setTotal, entity }) {
  const isHistoryOpen = useSelector((state) => state.searchHistory.isOpen);

  const { loading, assets, total, listProps } = useSearchAssets(
    propertyId,
    searchTerm,
    sort,
  );

  useSetListTotal(total, setTotal, loading);

  if (loading) {
    return <Loader />;
  }

  if (total === 0 && !isHistoryOpen) {
    if (searchTerm === '') {
      return <EmptyAssetsListScreen />;
    }

    return <NoResultsFoundScreen />;
  }

  return (
    <InfinityList
      items={assets}
      entity={entity}
      ItemComponent={AssetListItem}
      {...listProps}
    />
  );
}

AssetsList.propTypes = { propertyId: string, ...screenListPropTypes };
