import { SET_SEARCH_HISTORY } from './actions.js';

const defaultState = { isOpen: false };

export const searchHistoryReducer = (state = defaultState, action = {}) => {
  if (action.type === SET_SEARCH_HISTORY) {
    return { ...state, isOpen: action.payload };
  }
  return { ...state };
};
