import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { UpdateCollections } from 'poly-constants';
import { removePropDeep, forceTitleCase } from 'poly-utils';
import {
  prepareEmailStringByCollection,
  getEmailsSeparator,
  prepareEmailString,
} from 'poly-client-utils';

const UpdateEmailDataWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 0.6px solid #d1dade;
`;

const EmailDataRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  word-break: break-word;
  font-size: 11px;
  line-height: 14px;
  color: #000;
`;

const EmailDataLabel = styled.div`
  color: #999;
  width: 50px;
`;

const noWrapStyles = css`
  max-width: calc(100% - 50px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmailDataText = styled.div`
  white-space: pre-wrap;

  ${({ noWrap }) => !!noWrap && noWrapStyles};
`;

// prepareUpdateEmailData :: EmailData -> [String, Sting]
const prepareUpdateEmailData = R.compose(
  R.toPairs,
  R.reject(R.isNil),
  removePropDeep('__typename'),
);

export function UpdateEmailData(props) {
  const preparedData = prepareUpdateEmailData(props);

  return (
    <UpdateEmailDataWrapper data-testid="update-email-data">
      {preparedData.map(([key, data]) => (
        <EmailDataRow {...{ key }}>
          <EmailDataLabel>{forceTitleCase(key)}</EmailDataLabel>
          {R.is(Array, data) ? (
            data.map(({ address, name }, i) => (
              <EmailDataRow key={address}>
                {!!name && <EmailDataText>{name}</EmailDataText>}
                <a href={`mailto:${address}`}>
                  {name
                    ? prepareEmailString(address)
                    : prepareEmailStringByCollection(UpdateCollections)(
                        address,
                      )}
                </a>
                {getEmailsSeparator(data.length, i)}
              </EmailDataRow>
            ))
          ) : (
            <EmailDataText noWrap={key === 'subject'}>{data}</EmailDataText>
          )}
        </EmailDataRow>
      ))}
    </UpdateEmailDataWrapper>
  );
}
