import * as R from 'ramda';
import { useCallback } from 'react';
import { gql, useSubscription } from '@apollo/client';
import { useTableInfiniteScrollQuery } from 'poly-client-utils';
import { debounce, tryCallFunction } from 'poly-utils';

import { useStateInputForInfiniteListQuery } from '../../hooks/useStateInputForInfiniteListQuery.js';

const projectUpdatesQuery = gql`
  query projectUpdatesQuery($projectId: ID!, $input: DocumentUpdatesInput!) {
    project(projectId: $projectId) {
      _id
      documentUpdates(input: $input) {
        hits {
          _id
          title
          message
          createdAt
          createdBy {
            _id
            fullName
          }
          mentions {
            users {
              _id
              fullName
            }
            groups {
              _id
              name
            }
          }
          emailData {
            from {
              name
              address
            }
            to {
              name
              address
            }
            cc {
              name
              address
            }
            subject
          }
          attachments {
            _id
            url
            fileName
            fileType
            emailParams {
              to
            }
          }
        }
        total
      }
    }
  }
`;

const updatesSubscription = gql`
  subscription updatesSubscription($input: SearchUpdateChangedInput!) {
    searchUpdateChanged(input: $input) {
      id
      type
    }
  }
`;

export const useProjectUpdates = (projectId) => {
  const input = useStateInputForInfiniteListQuery({ createdAt: -1 });

  const {
    data,
    loading,
    refetch,
    tableProps: listProps,
  } = useTableInfiniteScrollQuery(projectUpdatesQuery, input, {
    endpointName: ['project', 'documentUpdates'],
    variables: { projectId },
    pageSize: 25,
  });

  const debouncedRefetch = useCallback(
    debounce(2000)(() => tryCallFunction(refetch)()),
    [refetch],
  );

  const documentId = R.path(['project', '_id'], data);

  useSubscription(updatesSubscription, {
    shouldResubscribe: data,
    onData: debouncedRefetch,
    variables: { input: { documentId } },
    skip: !documentId,
  });

  const updates = R.pathOr([], ['project', 'documentUpdates', 'hits'], data);

  const total = R.pathOr(0, ['project', 'documentUpdates', 'total'], data);

  return { total, updates, loading, listProps };
};
