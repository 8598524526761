import { func } from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';

import { Button } from '../../components/Button.js';
import { SubTitleText } from '../../components/Text.js';

const ButtonS = styled(Button)`
  width: 100%;
  color: #58628d;
  background: #fff;
  justify-content: flex-start;
  border: ${({ border }) => (border ? '2px solid  #49DBDB' : 'none')};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export function UserTypeComponent({ handleSelect }) {
  const [isTechnician, setIsTechnician] = useState(null);

  return (
    <>
      <Wrapper>
        <SubTitleText>Are you Technician?</SubTitleText>
        <ButtonS
          border={isTechnician === true}
          caption="Yes, I am a Technician"
          onClick={() => setIsTechnician(true)}
        />
        <ButtonS
          border={isTechnician === false}
          caption="No, I am not a Technician"
          onClick={() => setIsTechnician(false)}
        />
      </Wrapper>

      <Button
        iconName="send"
        caption="Submit"
        disabled={isTechnician === null}
        onClick={() => handleSelect(isTechnician)}
      />
    </>
  );
}

UserTypeComponent.propTypes = { handleSelect: func.isRequired };
