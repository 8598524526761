import React from 'react';

import {
  StaticHolderTextS,
  StaticHolderTitleS,
  StaticHolderWrapperS,
} from '../components/StaticHolder.js';
import { Icon } from '../icons/Icon.js';

export function NoResultsFoundScreen() {
  return (
    <StaticHolderWrapperS>
      <Icon name="noResults" />
      <StaticHolderTitleS>No results found</StaticHolderTitleS>
      <StaticHolderTextS>
        Please make sure your words are spelled correctly
      </StaticHolderTextS>
    </StaticHolderWrapperS>
  );
}

// Note: this prevents Sonar's
// inconsistent return type error
// at HomeScreen component
NoResultsFoundScreen.propTypes = {};
