import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { UPDATE_ASSET_PERMISSION } from 'poly-security';

import { useHasPermissionToCreateAsset } from '../screens/ScanQrCode/ScanQrCode.js';

export const useHasPermissionToCreateEditAsset = () => {
  const canCreateAsset = useHasPermissionToCreateAsset();
  const canUpdateAsset = useHasUserAccessWithPermission(
    UPDATE_ASSET_PERMISSION,
  );

  return canCreateAsset && canUpdateAsset;
};
