export const assetModelsStoreName = 'assetModelsStore';

export const assetsStoreName = 'assetsStore';

export const cacheSettingsStoreName = 'cacheSettingsStore';

export const assetProceduresStoreName = 'assetProceduresStore';

const assetModelsStoreConfig = {
  name: assetModelsStoreName,
  keyPath: '_id',
  indexes: [{ fieldName: 'manufacturerId', options: { unique: false } }],
};

const assetsStoreConfig = {
  name: assetsStoreName,
  keyPath: 'idbKey',
  indexes: [
    { fieldName: 'syncStatus', options: { unique: false } },
    { fieldName: 'qrCodeId', options: { unique: true } },
  ],
};

const cacheSettingsConfig = {
  name: cacheSettingsStoreName,
  keyPath: 'key',
};

const assetProceduresStoreConfig = {
  keyPath: 'idbKey',
  name: assetProceduresStoreName,
  indexes: [{ fieldName: 'syncStatus', options: { unique: false } }],
};

export const assetScannerIndexedDBStores = [
  assetModelsStoreConfig,
  assetsStoreConfig,
  cacheSettingsConfig,
  assetProceduresStoreConfig,
];
