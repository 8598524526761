import { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { assetScannerIndexedDb } from '../offline/indexedDb/indexedDb.js';
import {
  subscribeIdbStoreChange,
  unsubscribeIdbStoreChange,
} from '../offline/indexedDb/indexedDbStoreChangeEvent.js';

// sortAssetByCreatedAt :: [Asset] -> [Asset]
const sortAssetByCreatedAt = R.compose(
  R.reverse,
  R.sortBy(R.compose(R.prop('createdAt'))),
);

export const useIndexedDbStore = (indexedDbStoreName) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { isSyncing } = useSelector((state) => state.syncQueueStatus);

  useEffect(() => {
    const getAssetsQueue = async () => {
      setLoading(true);
      const iDb = await assetScannerIndexedDb();
      const entities = await iDb.getAll(indexedDbStoreName);
      setData(entities || []);
      setLoading(false);
    };

    getAssetsQueue();

    subscribeIdbStoreChange(getAssetsQueue);

    return () => unsubscribeIdbStoreChange(getAssetsQueue);
  }, [isSyncing]);

  return {
    data: sortAssetByCreatedAt(data),
    loading,
    total: data.length,
  };
};
