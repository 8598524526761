import React from 'react';

import { Router } from './Router.js';
import { ThemeProvider } from './providers/ThemeProvider.js';
import { StoreProvider } from './providers/StoreProvider.js';
import { ApolloClientProvider } from './providers/ApolloProvider.js';
import { ScannerOldVersionDetector } from './components/ScannerOldVersionDetector.js';
import { ModalProvider } from './providers/ModalProvider.js';
import { NetworkStatusProvider } from './providers/NetworkStatusProvider.js';
import { OfflineSupport } from './offline/OfflineSupport.js';

export function App() {
  return (
    <ApolloClientProvider>
      <StoreProvider>
        <ThemeProvider>
          <NetworkStatusProvider>
            <ModalProvider>
              <ScannerOldVersionDetector />
              <OfflineSupport />
              <Router />
            </ModalProvider>
          </NetworkStatusProvider>
        </ThemeProvider>
      </StoreProvider>
    </ApolloClientProvider>
  );
}
