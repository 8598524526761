export const PropertySortingOptions = [
  { value: 'name', label: 'Sort by Name' },
  { value: 'branchManager.name', label: 'Sort by Manager' },
];

export const AssetSortingOptions = [
  { value: 'type.name', label: 'Sort by Type' },
  { value: 'qrCodeId', label: 'Sort by QR' },
  { value: 'manufacturerDoc.name', label: 'Sort by Make' },
];

export const ProjectSortingOptions = [
  { value: 'status', label: 'Sort by Status' },
  { value: 'priority', label: 'Sort by Priority' },
  { value: 'createdAt', label: 'Sort by Created At' },
  { value: 'startDate', label: 'Sort by Start Date' },
  { value: 'endDate', label: 'Sort by End Date' },
];
