import { useState, useEffect } from 'react';

export const useComponentHeightById = (id, defaultHeight) => {
  const [componentHeight, setComponentHeight] = useState(defaultHeight);

  const elem = document.getElementById(id);

  useEffect(() => {
    if (elem?.offsetHeight) {
      setComponentHeight(elem?.offsetHeight);
    }
  }, [elem]);

  return { componentHeight };
};
