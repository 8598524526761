import React from 'react';
import * as R from 'ramda';
import { getThemeColor, getThemeFont } from 'poly-book-admin';
import styled from 'styled-components';
import { arrayOf, func, oneOfType, shape, string, node } from 'prop-types';
import { Text } from './Text.js';

const SelectorContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  & > div {
    width: ${R.prop('optionWidth')};
    height: ${R.prop('optionHeight')};
  }
  justify-content: space-between;
`;

const OptionContainer = styled.div`
  margin-bottom: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${getThemeColor(['primaryLight'])};
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-family: ${getThemeFont(['defaultFont'])};
  text-align: center;
  padding: 0 15px;
  box-sizing: border-box;
  border: ${({ isSelected }) => isSelected && '2px solid'};
  border-color: ${getThemeColor(['secondaryRegular'])};
`;

const SubTitle = styled(Text)`
  font-size: 10px;
  line-height: 12px;
  color: ${getThemeColor(['scaleMid'])};
`;

function Option({ id, children, value, onClick }) {
  const onTileClick = () => onClick(id);
  const isSelected = value === id;

  return (
    <OptionContainer isSelected={isSelected} onClick={onTileClick}>
      {children}
    </OptionContainer>
  );
}

const SelectLabel = styled(Text)`
  color: ${getThemeColor(['midDark'])};
  width: 100%;
  margin-bottom: 12px;
  font-size: 14px;
`;

Option.propTypes = {
  id: string.isRequired,
  children: arrayOf(oneOfType([string, node])).isRequired,
  value: string,
  onClick: func,
};

export function Selector({
  options,
  optionWidth,
  optionHeight,
  value,
  onClick,
  label,
}) {
  return (
    <SelectorContainer optionWidth={optionWidth} optionHeight={optionHeight}>
      {label && <SelectLabel>{label}</SelectLabel>}
      {options.map(({ id, title, subTitle }) => (
        <Option key={id} id={id} onClick={onClick} value={value}>
          {title}
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </Option>
      ))}
    </SelectorContainer>
  );
}

Selector.propTypes = {
  options: arrayOf(
    shape({
      title: string.isRequired,
      id: string.isRequired,
    }),
  ),
  optionWidth: string.isRequired,
  optionHeight: string.isRequired,
  onClick: func,
  value: string,
  label: string,
};
