import React from 'react';
import styled from 'styled-components';
import { string, shape, arrayOf, func } from 'prop-types';

import { Select } from './Select/Select.js';

const SortBySelectS = styled(Select)`
  width: 160px;

  select {
    padding: 7px 13px;
    color: #272728;
    border-radius: unset;
  }

  svg {
    top: 28%;
  }
`;

export function SortSelect({ options, sortingOption, setSortingOption }) {
  return (
    <SortBySelectS
      options={options}
      placeholder="Sort by"
      value={sortingOption}
      onChange={setSortingOption}
    />
  );
}

const optionPropTypes = shape({
  value: string.isRequired,
  label: string.isRequired,
});

SortSelect.propTypes = {
  sortingOption: string.isRequired,
  setSortingOption: func.isRequired,
  options: arrayOf(optionPropTypes).isRequired,
};
