import { useApolloClient } from '@apollo/client';
import { useUserLogout } from 'poly-client-utils';
import { useLocation, useParams } from 'poly-client-routing/src/index.js';

import { routes } from '../../routes.js';
import { ASSET_SCANNER_USER_TYPE_KEY } from '../Login/LoginScreen.js';

export const useLogOutSupplier = () => {
  const client = useApolloClient();
  const logOutUser = useUserLogout();

  const location = useLocation();
  const params = useParams();

  return (skipRedirect = false) => {
    localStorage.removeItem(ASSET_SCANNER_USER_TYPE_KEY);
    logOutUser({
      client,
      localStorage,
      skipRedirect,
      loginRoute: routes.login,
      routeParams: {
        from: {
          ...location,
          params: { ...params, isSupplierCheckIn: true },
        },
      },
    });
  };
};
