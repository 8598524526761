import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useNetworkStatus } from '../providers/NetworkStatusProvider.js';
import { publishIdbStoreChangedEvent } from './indexedDb/indexedDbStoreChangeEvent.js';
import { changeSyncQueueStatusAction } from './useEntitySyncStatusQueue.js';

export const useSyncPendingCache = ({
  hasPermission,
  isAllowedTab,
  workerInstance,
}) => {
  const { isOnline } = useNetworkStatus();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOnline && hasPermission && isAllowedTab) {
      dispatch(
        changeSyncQueueStatusAction({
          isSyncing: true,
        }),
      );

      workerInstance.postMessage({});

      // eslint-disable-next-line no-param-reassign
      workerInstance.onmessage = (e) => {
        publishIdbStoreChangedEvent();

        if (e.data === 'close') {
          dispatch(
            changeSyncQueueStatusAction({
              isSyncing: false,
            }),
          );

          workerInstance.terminate();
        }
      };
    }
  }, [isOnline, hasPermission]);

  useEffect(() => {
    if (!isOnline && hasPermission) {
      dispatch(
        changeSyncQueueStatusAction({
          isSyncing: false,
        }),
      );
    }
  }, [isOnline]);
};
