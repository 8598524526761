import * as R from 'ramda';

// prepareBeforeInsert :: String -> [String] -> [String]
const prepareBeforeInsert = (record) =>
  R.compose(R.take(25), R.uniq, R.prepend(record), R.defaultTo([]));

// prepareWithRemove :: String -> [String] -> [String]
const prepareWithRemove = (record) =>
  R.compose(R.reject(R.equals(record)), R.defaultTo([]));

export const useHistoryCacheByEntity = () => {
  const getHistoryCache = () => {
    const cacheHistory = localStorage.getItem('searchHistory');

    return JSON.parse(cacheHistory) || {};
  };

  const getHistoryRecords = (entity) => {
    const historyCache = getHistoryCache();

    return historyCache[entity] || [];
  };

  const updateHistory = (record, entity, shouldRemove) => {
    if (R.isEmpty(record)) return null;

    const historyCache = getHistoryCache();
    const oldRecords = getHistoryRecords(entity);

    const prepareUpdatesRecords = shouldRemove
      ? prepareWithRemove
      : prepareBeforeInsert;

    const updatedRecords = prepareUpdatesRecords(record)(oldRecords);

    const updatedHistory = JSON.stringify({
      ...historyCache,
      [entity]: updatedRecords,
    });

    return localStorage.setItem('searchHistory', updatedHistory);
  };

  const setHistoryRecord = (record, entity) =>
    updateHistory(record, entity, false);

  const removeHistoryRecord = (record, entity) =>
    updateHistory(record, entity, true);

  const clearHistory = (entity) => {
    const historyCache = getHistoryCache();

    const updatedHistory = JSON.stringify({
      ...historyCache,
      [entity]: [],
    });

    return localStorage.setItem('searchHistory', updatedHistory);
  };

  return {
    clearHistory,
    setHistoryRecord,
    getHistoryRecords,
    removeHistoryRecord,
  };
};
