import React from 'react';
import styled from 'styled-components';
import { getThemeColor, getThemeFont } from 'poly-book-admin';
import { bool, number, string } from 'prop-types';
import { Icon } from '../icons/Icon.js';
import { CONTACT_MOBILE } from '../constants.js';
import { LinkButton } from './Button.js';

const PhoneLinkC = styled.a`
  font-size: ${({ size }) => size}px;
  font-family: ${getThemeFont(['defaultFont'])};
  font-style: normal;
  font-weight: normal;
  color: ${({ color }) => getThemeColor([color])};
  text-decoration: none;
  svg {
    margin-right: 10px;
    vertical-align: bottom;
    path {
      stroke: ${({ color }) => getThemeColor([color])};
    }
  }
  vertical-align: center;
`;

export function PhoneLink({ phone, size, color, withIcon }) {
  return (
    <PhoneLinkC href={`tel:${phone}`} size={size} color={color}>
      {withIcon && (
        <Icon name="phone" width={size} height={size} color="#12347A" />
      )}
      {phone}
    </PhoneLinkC>
  );
}

PhoneLink.propTypes = {
  withIcon: bool,
  phone: string.isRequired,
  size: number,
  color: string,
};

PhoneLink.defaultProps = {
  withIcon: false,
  size: 20,
  color: 'primaryRegular',
};
export function ContactPhoneLink({ withIcon, ...props }) {
  return <PhoneLink phone={CONTACT_MOBILE} withIcon={withIcon} {...props} />;
}

ContactPhoneLink.propTypes = {
  withIcon: bool,
};

ContactPhoneLink.defaultProps = {
  withIcon: true,
};

export function PhoneButtonLink({ inverted }) {
  return (
    <LinkButton href={`tel:${CONTACT_MOBILE}`} inverted={inverted}>
      <Icon name="phone" />
      {CONTACT_MOBILE}
    </LinkButton>
  );
}

PhoneButtonLink.propTypes = {
  inverted: bool,
};
