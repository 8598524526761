import React, { useContext, useMemo, useState, useEffect } from 'react';
import { node } from 'prop-types';

const NetworkStatusContext = React.createContext({});

export function NetworkStatusProvider({ children }) {
  const isOnline = navigator.onLine;

  const [networkStatus, setNetworkStatus] = useState({
    isOnline,
    isWasOffline: !isOnline,
  });

  useEffect(() => {
    const setOnline = () =>
      setNetworkStatus({
        isWasOffline: true,
        isOnline: true,
      });

    const setOffline = () =>
      setNetworkStatus({
        isWasOffline: false,
        isOnline: false,
      });

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  const contextValue = useMemo(() => networkStatus, [networkStatus]);

  return (
    <NetworkStatusContext.Provider value={contextValue}>
      {children}
    </NetworkStatusContext.Provider>
  );
}

NetworkStatusProvider.propTypes = {
  children: node.isRequired,
};

export const useNetworkStatus = () => {
  const networkContext = useContext(NetworkStatusContext);
  return networkContext;
};
