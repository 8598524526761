import React from 'react';
import { node } from 'prop-types';

import { LayoutContainer } from './NavigationLayout.js';
import { AuthRequired } from '../components/AuthRequired.js';
import {
  SyncAssetsQueueStatus,
  useSyncAssetsQueueStatusHeight,
} from '../offline/SyncAssetsQueueStatus.js';
import { useGlobalClient } from './useGlobalClient.js';

export function CommonLayout({ children }) {
  const syncCompHeight = useSyncAssetsQueueStatusHeight();
  useGlobalClient();

  return (
    <AuthRequired>
      <SyncAssetsQueueStatus />
      <LayoutContainer syncCompHeight={syncCompHeight}>
        {children}
      </LayoutContainer>
    </AuthRequired>
  );
}

CommonLayout.propTypes = {
  children: node.isRequired,
};
