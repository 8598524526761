import { ModalOverlay } from 'poly-book-admin';
import { node } from 'prop-types';
import * as R from 'ramda';
import React, { useContext, useState, useMemo } from 'react';

const useModal = () => {
  const [modals, setModals] = useState([]);

  const openModal = ({ id, content }) =>
    setModals([...R.reject(R.propEq('id', id), modals), { id, content }]);

  const closeModal = (id) => setModals(R.reject(R.propEq('id', id), modals));

  return {
    openModal,
    closeModal,
    modals,
  };
};

const ModalContext = React.createContext({});

export function ModalProvider({ children }) {
  const { openModal, closeModal, modals } = useModal();

  const modalsContextValue = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    [modals],
  );
  return (
    <ModalContext.Provider value={modalsContextValue}>
      {children}
      {modals.length !== 0 &&
        modals.map(({ id, content }) => (
          <ModalOverlay key={id} isOpen modalId={id}>
            {content}
          </ModalOverlay>
        ))}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: node.isRequired,
};

export const useModalContext = () => {
  const modalContext = useContext(ModalContext);
  return modalContext;
};
