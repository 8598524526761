import { useEffect, useState } from 'react';

import { useHasPermissionToCreateEditAsset } from '../hooks/useHasPermissionToCreateEditAsset.js';
import { useCacheDataForOffline } from './cache/useCacheDataForOffline.js';
import { useOfflineModal } from './useOfflineModal.js';
import { useSynchronizeCacheData } from './cache/useSynchronizeCacheData.js';
import { useEntitySyncStatusQueue } from './useEntitySyncStatusQueue.js';
import { useSyncPendingCache } from './useSyncPendingCache.js';
import { useDeleteSyncedEntities } from './useDeleteSyncedEntities.js';
import { useUserHasAccessToCheckInProject } from '../hooks/useUserHasAccessToCheckInProject.js';
import {
  assetProceduresStoreName,
  assetsStoreName,
} from './indexedDb/indexedDbStores.js';

const useIsActiveTab = () => {
  const [isActiveTab, setIsActiveTab] = useState(true);

  useEffect(() => {
    const visibilityChangeCallback = () => {
      if (document.visibilityState === 'visible') {
        setIsActiveTab(true);
      } else {
        setIsActiveTab(false);
      }
    };

    window.addEventListener('visibilitychange', visibilityChangeCallback);

    return () =>
      window.removeEventListener('visibilitychange', visibilityChangeCallback);
  }, []);

  return isActiveTab;
};

const useSyncCachedEntities = ({
  isAllowedTab,
  hasPermissionToSubmitAsset,
  hasAccessToCheckInProject,
}) => {
  const syncAssetWorker = new Worker(
    new URL('./syncPendingAssetsWorker.js', import.meta.url),
  );

  useSyncPendingCache({
    hasPermission: hasPermissionToSubmitAsset,
    workerInstance: syncAssetWorker,
    isAllowedTab,
  });

  const syncAssetProceduresWorker = new Worker(
    new URL('./syncPendingAssetsProceduresWorker.js', import.meta.url),
  );

  useSyncPendingCache({
    hasPermission: hasAccessToCheckInProject,
    workerInstance: syncAssetProceduresWorker,
    isAllowedTab,
  });
};

export function OfflineSupport() {
  const hasPermissionToSubmitAsset = useHasPermissionToCreateEditAsset();

  const hasAccessToCheckInProject = useUserHasAccessToCheckInProject();

  const isAllowedTab = useIsActiveTab();

  useCacheDataForOffline({ hasPermissionToSubmitAsset }, isAllowedTab);

  useSynchronizeCacheData(hasPermissionToSubmitAsset, isAllowedTab);

  useOfflineModal({ hasPermissionToSubmitAsset, hasAccessToCheckInProject });

  useEntitySyncStatusQueue({
    hasPermission: hasPermissionToSubmitAsset,
    storeName: assetsStoreName,
    isAllowedTab,
  });

  useEntitySyncStatusQueue({
    hasPermission: hasAccessToCheckInProject,
    storeName: assetProceduresStoreName,
    isAllowedTab,
  });

  useSyncCachedEntities({
    isAllowedTab,
    hasAccessToCheckInProject,
    hasPermissionToSubmitAsset,
  });

  useDeleteSyncedEntities({
    hasPermission: hasPermissionToSubmitAsset || hasAccessToCheckInProject,
    isAllowedTab,
  });

  return null;
}
