import * as R from 'ramda';
import styled from 'styled-components';
import React, { useRef, useState, useEffect } from 'react';

import { Text } from './Text.js';

const ScreenTitleS = styled(Text)`
  line-height: 26px;
  font-size: ${R.prop('size')}px;
`;

export function ScreenTitle(props) {
  const defaultSize = 22;

  const titleRef = useRef(null);
  const [size, setSize] = useState(defaultSize);

  useEffect(() => {
    if (size === defaultSize) {
      const allowedHeight = 52;
      const titleHeight = titleRef?.current?.offsetHeight || 50;

      const newSize = titleHeight > allowedHeight ? 20 : 22;

      setSize(newSize);
    }
  }, []);

  return <ScreenTitleS {...props} ref={titleRef} size={size} />;
}
