import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string } from 'prop-types';
import { pathOrNothingUI } from 'poly-client-utils';
import { getThemeColor } from 'poly-book-admin';

import { NoImageHolder } from './NoImageHolder.js';
import { BlockWithLabel } from './BlockWithLabel.js';

const AssetImage = styled.img`
  width: 100%;
  height: 120px;
  object-fit: cover;
  object-position: 25% 25%;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-break: break-word;
`;

const Title = styled.h5`
  margin: 0 0 4px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${getThemeColor(['primaryDark2'])};
`;

// getAssetEquipmentByProps :: Asset -> String
export const getAssetEquipmentByProps = (newEquipProp, oldEquipProp) =>
  R.ifElse(
    R.prop(newEquipProp),
    pathOrNothingUI([newEquipProp]),
    pathOrNothingUI([oldEquipProp, 'name']),
  );

export function AssetListCard({ asset }) {
  return (
    <>
      {asset?.photo?.url ? (
        <AssetImage src={asset?.photo?.url} />
      ) : (
        <NoImageHolder width="120px" height="120px" />
      )}
      <DescriptionWrapper>
        <Title>{asset.type?.name}</Title>
        <BlockWithLabel
          label="QR Code"
          value={pathOrNothingUI(['qrCodeId'], asset)}
          direction="row"
        />
        <BlockWithLabel
          label="Make"
          value={getAssetEquipmentByProps(
            'newManufacturerName',
            'manufacturerDoc',
          )(asset)}
          direction="row"
        />
        <BlockWithLabel
          label="Model #"
          value={getAssetEquipmentByProps('newModelName', 'modelDoc')(asset)}
          direction="row"
        />
      </DescriptionWrapper>
    </>
  );
}

AssetListCard.propTypes = {
  asset: shape({
    type: shape({
      name: string,
    }),
    qrCodeId: string,
    manufacturerDoc: shape({
      name: string,
    }),
    modelDoc: shape({
      name: string,
    }),
    photo: shape({ url: string.isRequired }),
  }),
};
