import React from 'react';

import { EntitiesQueueScreenBase } from './EntitiesQueueScreenBase.js';
import { AssetQueueItem } from './AssetQueueItem.js';
import { useIndexedDbStore } from '../../hooks/useIndexedDbStore.js';
import { assetsStoreName } from '../../offline/indexedDb/indexedDbStores.js';

export function AssetsQueueScreen() {
  const { data, ...restProps } = useIndexedDbStore(assetsStoreName);
  return (
    <EntitiesQueueScreenBase
      {...restProps}
      entities={data}
      itemKey="qrCodeId"
      entityName="asset"
      QueueItemComponent={AssetQueueItem}
      indexedDbStoreName={assetsStoreName}
    />
  );
}
