import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { string, element, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getThemeColor } from 'poly-book-admin';
import { forceTitleCase } from 'poly-utils';

import { Icon } from '../../icons/Icon.js';
import { useHistoryCacheByEntity } from '../../hooks/useHistoryCacheByEntity.js';
import { SET_SEARCH_HISTORY } from '../../redux/searchHistory/actions.js';
import { useThemeColor } from '../../hooks/useThemeColor.js';

const SubHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 20px 24px;
  border-bottom: 2px solid ${getThemeColor(['midLight'])};
`;

const SearchInputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background: #fff;
  position: relative;
`;

const inputFontStyles = css`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const SearchInputS = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  background: #fff;
  padding: 12px 40px 10px 12px;
  border: none;

  ${inputFontStyles}

  &::placeholder {
    ${inputFontStyles}
    color: ${getThemeColor(['midDark'])};
  }

  &:focus-visible {
    outline: 1px solid ${getThemeColor(['secondaryDark'])};
  }
`;

const SearchIconS = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 12px;
`;

const ClearIconS = styled(Icon)`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 15px;
  right: 15px;
`;

export function SearchInput({ onChange, entity, Toolbar }) {
  const isHistoryOpen = useSelector((state) => state.searchHistory.isOpen);
  const { setHistoryRecord } = useHistoryCacheByEntity();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const iconColor = useThemeColor(['secondaryDark']);

  const onFocus = () => {
    dispatch({ type: SET_SEARCH_HISTORY, payload: true });
  };

  const onBlur = (e) => {
    setHistoryRecord(e.target.value, entity);
    dispatch({ type: SET_SEARCH_HISTORY, payload: false });
  };

  const onInputChange = (e) => {
    onChange(e.target.value);

    if (isHistoryOpen) {
      dispatch({ type: SET_SEARCH_HISTORY, payload: false });
    }
  };

  const onInputClear = () => {
    onChange('');
    inputRef.current.value = '';
    inputRef.current.focus();
  };

  return (
    <SubHeaderWrapper>
      <SearchInputWrapper>
        <SearchInputS
          placeholder={`Search ${forceTitleCase(entity)}`}
          onChange={onInputChange}
          id="search-input"
          onFocus={onFocus}
          onBlur={onBlur}
          ref={inputRef}
        />
        {inputRef?.current?.value ? (
          <ClearIconS name="close" onClick={onInputClear} />
        ) : (
          <SearchIconS name="search" color={isHistoryOpen && iconColor} />
        )}
      </SearchInputWrapper>
      {Toolbar}
    </SubHeaderWrapper>
  );
}

SearchInput.propTypes = {
  Toolbar: element,
  entity: string.isRequired,
  onChange: func.isRequired,
};
