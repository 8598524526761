import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ASC_SORT_ORDER, ClientStatuses } from 'poly-constants';
import { ASSET_SCANNER_APP_NAME, READ_CLIENT_PERMISSION } from 'poly-security';
import { keywordSortQuery } from 'poly-client-utils/src/sorting.js';
import { entityToOptionByLabelPath } from 'poly-client-utils/src/select.js';
import { useHasUserAccessWithPermission } from 'poly-client-utils/src/hooks/useHasUserAccessWithPermission.js';

import { Select } from './Select/Select.js';
import { SET_GLOBAL_CLIENT } from '../redux/currentClient/actions.js';

export const GLOBAL_CLIENT_SELECTED_ID_KEY = 'GLOBAL_CLIENT_SELECTED_ID_KEY';

export const CLIENTS_SEARCH_QUERY = gql`
  query CLIENTS_SEARCH_FILTER($searchInput: CollectionSearchParams!) {
    searchClients(input: $searchInput) {
      hits {
        _id
        name
      }
      total
    }
  }
`;

export const searchClientsQueryInput = {
  size: 1000,
  query: {
    bool: {
      must: [
        { match: { status: ClientStatuses.ACTIVE } },
        { match: { apps: ASSET_SCANNER_APP_NAME } },
      ],
    },
  },
  sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
};

const SelectWrapper = styled.div`
  padding: 5px 26px 0 26px;
`;

// getClientSelectOptions :: SearchClients -> [Option]
const getClientSelectOptions = R.compose(
  R.uniq,
  R.map(entityToOptionByLabelPath(['name'])),
  R.pathOr([], ['searchClients', 'hits']),
);

// getDefaultClientId ::  (ID, SearchClients) -> Option
const getDefaultClientId = (id, data) =>
  R.compose(
    R.prop('_id'),
    R.either(R.head, R.last),
    R.juxt([R.find(R.propEq('_id', id)), R.head]),
    R.pathOr([], ['searchClients', 'hits']),
  )(data);

export function GlobalClientSelect() {
  const hasPermissionToReadClient = useHasUserAccessWithPermission(
    READ_CLIENT_PERMISSION,
  );
  const cachedClientId = localStorage.getItem(GLOBAL_CLIENT_SELECTED_ID_KEY);

  const dispatch = useDispatch();

  const clientId = useSelector((state) => state.globalClient?.clientId);
  const { data, loading } = useQuery(CLIENTS_SEARCH_QUERY, {
    variables: {
      searchInput: searchClientsQueryInput,
    },
    skip: !hasPermissionToReadClient,
  });
  const isSingleClient = !loading && data?.searchClients?.total === 1;

  useEffect(() => {
    if (!clientId && !loading && !isSingleClient) {
      dispatch({
        type: SET_GLOBAL_CLIENT,
        payload: { clientId: getDefaultClientId(cachedClientId, data) },
      });
    }
  }, [data]);

  if (!data?.searchClients || isSingleClient || !clientId || loading) {
    return null;
  }

  const options = getClientSelectOptions(data);

  const handleChange = (value) => {
    localStorage.setItem(GLOBAL_CLIENT_SELECTED_ID_KEY, value);
    dispatch({ type: SET_GLOBAL_CLIENT, payload: { clientId: value } });
  };

  return (
    <SelectWrapper>
      <Select
        value={clientId}
        options={options}
        onChange={handleChange}
        placeholder="Select Client"
        isLoading={loading}
      />
    </SelectWrapper>
  );
}
