import React, { useEffect } from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { useNavigate, useLocation, matchRoutes } from 'poly-client-routing';
import { insertQueryParamsIntoURL } from 'poly-utils';
import { getThemeColor } from 'poly-book-admin';

import { routes } from '../routes.js';
import { Icon } from '../icons/Icon.js';
import { defaultProjectsFilterState } from '../redux/project/reducer.js';
import { SET_PROJECTS_FILTER } from '../redux/project/actions.js';
import { useThemeColor } from '../hooks/useThemeColor.js';

const NavigationContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 68px;
  left: 0px;
  bottom: 0px;
  background: #ffffff;
  box-shadow: 0px -4px 26px rgba(0, 0, 0, 0.15);
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasText }) => (hasText ? 'space-between' : 'center')};
  align-items: center;
  width: 44px;
  height: 44px;
  background: ${({ hasText }) =>
    hasText ? 'none' : getThemeColor(['secondaryDark'])};
  cursor: pointer;
`;

const Text = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({ color }) => color || getThemeColor(['secondaryDark'])};
  margin: 0;
  padding: 0;
`;

const ButtonsWrapper = styled.div`
  margin: 12px 42px;
  display: flex;
  justify-content: space-between;
`;

function IconButton({ iconName, text, route }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const defaultColor = useThemeColor(['primaryDark']);
  const activeColor = useThemeColor(['secondaryDark']);

  const routesMatch = matchRoutes([{ path: routes.propertyAssets }], location);

  const isProjectsFilterRoute = route === routes.projects;

  const isAddAssetRoute = R.includes(route, [
    routes.scanQrCode,
    routes.onboarding,
  ]);

  const isPropertyToProjectsFilterRoute = R.pathEq(
    [0, 'route', 'path'],
    routes.propertyAssets,
    routesMatch,
  );

  const propertyId = R.path([0, 'params', 'propertyId'], routesMatch);

  const color = route === location.pathname ? activeColor : defaultColor;

  const handleClick = () => {
    if (isProjectsFilterRoute) {
      if (isPropertyToProjectsFilterRoute && !!propertyId) {
        dispatch({ type: SET_PROJECTS_FILTER, payload: { propertyId } });
      } else {
        dispatch({
          type: SET_PROJECTS_FILTER,
          payload: defaultProjectsFilterState,
        });
      }
    }

    navigate(
      isAddAssetRoute && !!propertyId
        ? insertQueryParamsIntoURL({ propertyId })(route)
        : route,
    );
  };

  return (
    <IconContainer hasText={!!text} onClick={handleClick}>
      <Icon name={iconName} color={color} />
      {text && <Text color={color}>{text}</Text>}
    </IconContainer>
  );
}

IconButton.propTypes = {
  text: string,
  route: string.isRequired,
  iconName: string.isRequired,
};

const checkOnboardingQuery = gql`
  query meQuery {
    me {
      _id
      isOnboardingComplete
    }
  }
`;

const isOnBoardingComplete = R.path(['me', 'isOnboardingComplete']);

const useIsOnboarding = () => {
  const { data } = useQuery(checkOnboardingQuery);
  return isOnBoardingComplete(data);
};

export function Navigation() {
  const isOnboardingComplete = useIsOnboarding();
  const addBtnUrl = isOnboardingComplete
    ? routes.scanQrCode
    : routes.onboarding;
  return (
    <NavigationContainer>
      <ButtonsWrapper>
        <IconButton iconName="home" text="Home" route={routes.home} />
        <IconButton iconName="add" route={addBtnUrl} />
        <IconButton iconName="list" text="Projects" route={routes.projects} />
      </ButtonsWrapper>
    </NavigationContainer>
  );
}

export function Redirect({ route }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(route);
  }, []);
  return null;
}

Redirect.propTypes = {
  route: string.isRequired,
};
