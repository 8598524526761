import * as R from 'ramda';
import { AssetStatuses } from 'poly-constants';
import { assocBy, isNilOrEmpty } from 'poly-utils';
import { gql } from '@apollo/client';
import { undefinedAsNullDeep } from 'poly-client-utils';
import { getAssetReplacementCost } from 'poly-utils/src/getAssetReplacementCost.js';

// formatMutationInputBase :: FormValues -> UpdateAssetInput
export const formatMutationInputBase = R.compose(
  R.omit(['withNewMake', 'withNewModel']),
  R.ifElse(
    R.both(R.prop('newModelName'), R.prop('withNewModel')),
    R.assoc('modelId', null),
    R.dissoc('newModelName'),
  ),
  R.ifElse(
    R.both(R.prop('newManufacturerName'), R.prop('withNewMake')),
    R.compose(R.assoc('modelId', null), R.assoc('manufacturerId', null)),
    R.dissoc('newManufacturerName'),
  ),
  R.converge(R.mergeLeft, [
    R.pickAll([
      'photo',
      'typeId',
      'serial',
      'modelId',
      'description',
      'warrantyEnd',
      'manufacturerId',
      'replacementCost',
    ]),
    R.omit([
      'isQrCodeUsed',
      'searchedMake',
      'searchedModel',
      'isAssetReplacementCostEnabled',
      'isReplacementCostChanged',
    ]),
  ]),
  R.when(
    R.either(R.prop('withNewMake'), R.prop('withNewModel')),
    R.assoc('status', AssetStatuses.PENDING),
  ),
);

// validateAssetFormQRCode :: FormValues -> String
export const validateAssetFormQRCode = R.applySpec({
  qrCodeId: R.ifElse(
    R.prop('isQrCodeUsed'),
    R.always('QR code already used'),
    R.always(undefined),
  ),
});

// formatCreateAssetMutationInput :: FormValues -> CreateAssetInput
export const formatCreateAssetMutationInput = R.compose(
  R.omit([
    'createdAt',
    'updatedAt',
    'syncStatus',
    'errorMsg',
    'idbKey',
    'photo',
  ]),
  R.mergeRight({ status: AssetStatuses.PENDING }),
  formatMutationInputBase,
);

export const addAssetMutation = gql`
  mutation addAssetMutation($input: CreateAssetInput!) {
    createAsset(input: $input) {
      asset {
        _id
      }
    }
  }
`;

// isAssetReplacementCostEnabled :: Asset -> Boolean
export const isAssetReplacementCostEnabled = R.pathOr(false, [
  'client',
  'configs',
  'assetScannerApp',
  'allowReplacementCost',
]);

// prepareAssetForFormInitialValues :: Asset -> FormValues
export const prepareAssetForFormInitialValues = R.compose(
  R.over(
    R.lensProp('photo'),
    R.unless(
      R.isNil,
      R.applySpec({ _id: R.prop('fileName'), preview: R.prop('url') }),
    ),
  ),
  R.pick([
    'typeId',
    'photo',
    'serial',
    'modelId',
    'qrCodeId',
    'location',
    'propertyId',
    'withNewMake',
    'warrantyEnd',
    'description',
    'newModelName',
    'withNewModel',
    'manufacturerId',
    'replacementCost',
    'newManufacturerName',
    'isAssetReplacementCostEnabled',
    'isReplacementCostChanged',
  ]),
  R.cond([
    [
      R.path(['modelDoc', '_id']),
      R.compose(
        assocBy('modelId', R.path(['modelDoc', '_id'])),
        R.assoc('newModelName', ''),
        R.assoc('withNewModel', false),
      ),
    ],
    [
      R.both(
        R.prop('manufacturerId'),
        R.compose(isNilOrEmpty, R.prop('newModelName')),
      ),
      R.assoc('withNewModel', false),
    ],
    [R.T, R.assoc('withNewModel', true)],
  ]),
  R.ifElse(
    R.path(['manufacturerDoc', '_id']),
    R.compose(
      assocBy('manufacturerId', R.path(['manufacturerDoc', '_id'])),
      R.assoc('newManufacturerName', ''),
      R.assoc('withNewMake', false),
    ),
    R.assoc('withNewMake', true),
  ),
  assocBy('isAssetReplacementCostEnabled', isAssetReplacementCostEnabled),
  assocBy('typeId', R.path(['type', '_id'])),
  assocBy('propertyId', R.path(['property', '_id'])),
  assocBy(
    'isReplacementCostChanged',
    R.ifElse(
      R.prop('replacementCost'),
      R.converge(R.complement(R.equals), [
        R.prop('replacementCost'),
        getAssetReplacementCost,
      ]),
      R.F,
    ),
  ),
);

// prepareAssetFormValuesToEditMutation :: FormValues -> UpdateAssetInput
export const prepareAssetFormValuesToEditMutation = R.compose(
  R.when(R.prop('photo'), R.dissoc('photo')),
  R.when(R.prop('modelId'), R.assoc('newModelName', '')),
  R.when(R.prop('manufacturerId'), R.assoc('newManufacturerName', '')),
  undefinedAsNullDeep,
  formatMutationInputBase,
  R.unless(R.prop('isReplacementCostChanged'), R.dissoc('replacementCost')),
);
