import * as R from 'ramda';
import { func } from 'prop-types';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import React, { useState, useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNavigate, useParams, useLocation } from 'poly-client-routing';
import { UpdateCollections, UpdateTypes } from 'poly-constants';
import { assocBy } from 'poly-utils';

import { Icon } from '../icons/Icon.js';
import { Button } from '../components/Button.js';
import { TextArea } from '../components/TextArea.js';
import { FormFieldWithLabel } from '../components/FormFieldWithLabel.js';
import { MultipleFilesUpload } from '../components/MultipleFilesUpload.js';
import { attachPhotoFormValidators } from '../components/AttachPhotoInput.js';

const addProjectUpdateFormId = 'addProjectUpdateFormId';

const addUpdateMutation = gql`
  mutation addUpdateMutation($input: CreateUpdateInput!) {
    createUpdate(input: $input) {
      _id
    }
  }
`;

const AddProjectUpdateScreenWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  padding: 24px;
  justify-content: space-between;
`;

const AddProjectUpdateSectionS = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 61px);
  width: 100%;
`;

const AddProjectUpdateFormTitleS = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  color: #12347a;
  margin: 20px 0 40px 0;
`;

const SubmitButtonS = styled(Button)`
  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;

    path {
      stroke: #fff;
    }
  }
`;

const AddProjectUpdateFormS = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
`;

function AddProjectUpdateFormRendered({ handleSubmit }) {
  const [fieldKey, setFieldKey] = useState(0);

  // we need this helper to trigger validation on one of the files remove
  // https://final-form.org/docs/react-final-form/types/FieldProps#validate
  const reinitializeValidation = () =>
    setFieldKey((state) => (state === 0 ? 1 : 0));

  return (
    <AddProjectUpdateFormS id={addProjectUpdateFormId} onSubmit={handleSubmit}>
      <FormFieldWithLabel
        required
        label="Notes"
        name="message"
        Component={TextArea}
        validators={[[R.identity, 'Update notes are required']]}
      />
      <FormFieldWithLabel
        key={fieldKey}
        name="filesAttachments"
        Component={MultipleFilesUpload}
        componentProps={{ reinitializeValidation }}
        validators={attachPhotoFormValidators(R.all)}
      />
    </AddProjectUpdateFormS>
  );
}

AddProjectUpdateFormRendered.propTypes = { handleSubmit: func.isRequired };

// prepareFormDataToMutation :: ID -> FormData -> CreateUpdateInput
const prepareFormDataToMutation = (documentId) =>
  R.compose(
    R.mergeLeft({
      documentId,
      title: 'Client Comment',
      type: UpdateTypes.CLIENT_UPDATE,
      collection: UpdateCollections.projects,
    }),
    R.ifElse(
      R.propSatisfies(R.isEmpty, 'filesAttachments'),
      R.dissoc('filesAttachments'),
      assocBy(
        'filesAttachments',
        R.compose(
          R.map(
            R.applySpec({
              upload: R.identity,
              fileName: R.prop('name'),
            }),
          ),
          R.prop('filesAttachments'),
        ),
      ),
    ),
  );

// generateBackAddUpdateRoute :: Location -> String
const generateBackAddUpdateRoute = R.compose(
  R.concat(R.__, '?tab=updates'),
  R.path(['state', 'previous', 'pathname']),
);

export function AddProjectUpdateScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const [inProcess, setInProcess] = useState(false);

  const backAddUpdateRoute = generateBackAddUpdateRoute(location);

  const [createUpdate] = useMutation(addUpdateMutation);

  const onCloseForm = () =>
    navigate(backAddUpdateRoute, { state: { previous: location } });

  const initialValues = useMemo(() => ({ filesAttachments: [] }), []);

  const onSubmit = async (formData) => {
    setInProcess(true);

    const input = prepareFormDataToMutation(projectId)(formData);

    await createUpdate({ variables: { input } });

    setInProcess(false);
    onCloseForm();
  };

  return (
    <AddProjectUpdateScreenWrapperS>
      <AddProjectUpdateSectionS>
        <Icon name="close" onClick={onCloseForm} />
        <AddProjectUpdateFormTitleS>Add New Update</AddProjectUpdateFormTitleS>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={AddProjectUpdateFormRendered}
        />
      </AddProjectUpdateSectionS>
      <SubmitButtonS
        type="submit"
        iconName="speech"
        loading={inProcess}
        caption="Add Update"
        form={addProjectUpdateFormId}
      />
    </AddProjectUpdateScreenWrapperS>
  );
}
