import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { instanceOf, oneOfType, shape, string } from 'prop-types';
import {
  convertCentsToDollars,
  formatCurrencyForViewPath,
  formatTotal,
  insertParamsIntoURL,
} from 'poly-utils';
import { UPDATE_ASSET_PERMISSION } from 'poly-security';
import {
  useHasUserAccessWithPermission,
  pathOrNothingUI,
  formatDateProp,
} from 'poly-client-utils';
import {
  useRouterQuery,
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
} from 'poly-client-routing';

import {
  ASSET_DETAILS_IMAGE_ID,
  IMAGE_PLACEHOLDER_HEIGHT,
  ASSET_DETAILS_CONTENT_ID,
} from './constants.js';
import { routes } from '../../routes.js';
import { Icon } from '../../icons/Icon.js';
import { Tabs } from '../../components/Tabs.js';
import { Image } from '../../components/Image.js';
import { Loader } from '../../components/Loader.js';
import { AssetProjectsTab } from './AssetProjectsTab.js';
import { ScreenHeader } from '../../components/ScreenHeader.js';
import { NoImageHolder } from '../../components/NoImageHolder.js';
import { BlockWithLabel } from '../../components/BlockWithLabel.js';
import { FlexColumnContainer } from '../../components/Containers.js';
import { useSetStatusBarColorOnMount } from '../../hooks/useSetStatusBarColorOnMount.js';
import { getAssetEquipmentByProps } from '../../components/AssetListCard.js';
import { useSearchProperties } from '../HomeScreen/useSearchProperties.js';
import { useAssetDetails } from '../../hooks/useAssetDetails.js';
import { useThemeColor } from '../../hooks/useThemeColor.js';
import { NotFoundScreen } from '../NotFoundScreen.js';
import { isAssetReplacementCostEnabled } from '../AssetForm/assetFormHelpers.js';

const Wrapper = styled(FlexColumnContainer)`
  min-height: 100%;
  background: #ffffff;
`;

const ContentWrapperS = styled(FlexColumnContainer)`
  height: ${R.prop('height')}px;
  overflow-y: auto;
`;

const AssetDetailWrapper = styled(FlexColumnContainer)`
  padding: 24px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
`;

function EdiAssetButton({ assetId }) {
  const navigate = useNavigate();
  const location = useLocation();
  const color = useThemeColor(['primaryRegular']);

  const editAssetUrl = insertParamsIntoURL({ assetId }, routes.editAsset);

  const onClick = () =>
    navigate(editAssetUrl, { state: { previous: location } });

  return (
    <Icon name="edit" color={color} width="20" height="20" onClick={onClick} />
  );
}

EdiAssetButton.propTypes = { assetId: string.isRequired };

// prepareAssetReplacementCost :: Asset -> String
const prepareAssetReplacementCost = R.compose(
  formatTotal,
  convertCentsToDollars,
  R.prop('replacementCost'),
);

function AssetDetailTab({ asset }) {
  const assetReplacementCost = prepareAssetReplacementCost(asset);

  return (
    <AssetDetailWrapper>
      <BlockWithLabel
        label="Make"
        value={getAssetEquipmentByProps(
          'newManufacturerName',
          'manufacturerDoc',
        )(asset)}
      />
      <BlockWithLabel
        label="Description"
        value={pathOrNothingUI(['description'], asset)}
      />
      <BlockWithLabel
        label="Model #"
        value={getAssetEquipmentByProps('newModelName', 'modelDoc')(asset)}
      />
      <BlockWithLabel
        label="Warranty Expiration Date"
        value={formatDateProp('warrantyEnd')(asset)}
      />
      <BlockWithLabel
        label="Serial Number"
        value={pathOrNothingUI(['serial'], asset)}
      />
      <BlockWithLabel
        label="QR Code"
        value={pathOrNothingUI(['qrCodeId'], asset)}
      />
      <BlockWithLabel
        label="Client Asset Code"
        value={pathOrNothingUI(['equipmentType'], asset)}
      />
      <BlockWithLabel
        label="Location Description"
        value={pathOrNothingUI(['location'], asset)}
      />
      {isAssetReplacementCostEnabled(asset) && (
        <BlockWithLabel
          label="Asset Replacement Cost"
          aa={convertCentsToDollars}
          value={assetReplacementCost}
        />
      )}
      <BlockWithLabel
        label="Purchase Price"
        value={formatCurrencyForViewPath(['purchasePrice'])(asset)}
      />
      <BlockWithLabel
        label="Commissioning Date"
        value={formatDateProp('commissioningDate')(asset)}
      />
    </AssetDetailWrapper>
  );
}

AssetDetailTab.propTypes = {
  asset: shape({
    location: string,
    qrCodeId: string,
    serial: string,
    manufacturerDoc: shape({
      name: string,
    }),
    modelDoc: shape({
      name: string,
    }),
    warrantyEnd: oneOfType([string, instanceOf(Date)]),
  }),
};

// getPreviousLocation :: Location -> Location
const getPreviousLocation = R.pathOr({}, ['state', 'previous']);

export function AssetDetailScreen() {
  const location = useLocation();
  const { assetId } = useParams();
  const { tab } = useRouterQuery(['tab']);
  const { asset, loading } = useAssetDetails(assetId);
  const [headerHeight, setHeaderHeight] = useState(75);
  const [isScrollDisabled, setScrollDisabled] = useState(true);
  const hasAccess = useHasUserAccessWithPermission(UPDATE_ASSET_PERMISSION);

  const routesMatch = matchRoutes(
    [{ path: routes.editAsset }],
    getPreviousLocation(location),
  );

  const { total } = useSearchProperties();

  const assetPhotoUrl = asset?.photo?.url;

  useSetStatusBarColorOnMount('#fff');

  useEffect(() => {
    const elem = document.getElementById('screen-header');
    if (elem?.offsetHeight) {
      setHeaderHeight(elem.offsetHeight);
    }
  }, [asset]);

  if (loading) {
    return <Loader />;
  }

  if (R.isEmpty(asset)) {
    return <NotFoundScreen />;
  }

  const wrapperHeight = window.innerHeight - headerHeight;

  const tabs = [
    {
      key: 'asset-details',
      title: 'Asset Details',
      content: <AssetDetailTab asset={asset} />,
    },
    {
      key: 'service-history',
      title: 'Service History',
      content: (
        <AssetProjectsTab
          assetId={asset?._id}
          wrapperHeight={wrapperHeight}
          isScrollDisabled={isScrollDisabled}
        />
      ),
    },
  ];

  const propertyAssetsUrl = insertParamsIntoURL(
    { propertyId: asset?.property._id },
    routes.propertyAssets,
  );

  const headerProps = {
    goBack: true,
    title: asset.type?.name,
    ...(routesMatch
      ? {
          route: total === 1 ? routes.home : propertyAssetsUrl,
        }
      : {}),
    ...(hasAccess
      ? {
          toolBar: <EdiAssetButton assetId={asset._id} />,
        }
      : {}),
  };

  const onScroll = (e) => {
    const elementId = e.target.id;

    if (elementId === ASSET_DETAILS_CONTENT_ID) {
      // fix for Android where scroll calculation never rich bottom point
      const elementScrollPosition = e.target.scrollTop + 1;
      const elementBottomPosition =
        e.target.scrollHeight - e.target.offsetHeight;

      if (elementScrollPosition >= elementBottomPosition && isScrollDisabled) {
        setScrollDisabled(false);
      }

      if (elementScrollPosition < elementBottomPosition && !isScrollDisabled) {
        setScrollDisabled(true);
      }
    }
  };

  return (
    <Wrapper onScroll={onScroll}>
      <ScreenHeader {...headerProps} />
      <ContentWrapperS height={wrapperHeight} id={ASSET_DETAILS_CONTENT_ID}>
        {assetPhotoUrl ? (
          <Image src={assetPhotoUrl} id={ASSET_DETAILS_IMAGE_ID} />
        ) : (
          <NoImageHolder height={`${IMAGE_PLACEHOLDER_HEIGHT}px`} />
        )}
        <Tabs tabs={tabs} activeTab={tab || tabs[0].key} />
      </ContentWrapperS>
    </Wrapper>
  );
}
