import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useReactiveQuery } from 'poly-client-utils';

const projectDetailsFields = gql`
  fragment projectDetailsFields on Project {
    _id
    status
    endDate
    priority
    startDate
    projectId
    description
    manager {
      fullName
    }
    contact {
      fullName
    }
  }
`;

const projectQuery = gql`
  query projectQuery($projectId: ID) {
    project(projectId: $projectId) {
      ...projectDetailsFields
    }
  }

  ${projectDetailsFields}
`;

const projectSubscription = gql`
  subscription projectSubscription($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      document {
        ...projectDetailsFields
      }
    }
  }

  ${projectDetailsFields}
`;

export const useProjectDetails = (projectId) => {
  const queryOptions = {
    variables: { projectId },
    fetchPolicy: 'network-only',
    skip: !projectId,
  };

  const subscriptionOptions = { variables: { input: { projectId } } };

  const { data, loading } = useReactiveQuery(
    projectQuery,
    projectSubscription,
    {
      queryOptions,
      subscriptionOptions,
    },
  );

  const project = R.pathOr({}, ['project'], data);

  return { project, loading };
};
