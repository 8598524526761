import React from 'react';
import { func, shape, string } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { getThemeColor, Icon } from 'poly-book-admin';
import styled from 'styled-components';

import { FlexColumnContainer } from './Containers.js';
import { Text } from './Text.js';
import { Image } from './Image.js';

const DropZoneContainer = styled(FlexColumnContainer)`
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 100px;
`;

const AttachImageIconContainer = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: ${getThemeColor(['secondaryRegular'])};
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  top: calc(50% - 11px);
  right: calc(50% - 11px);
`;

const AttachImageLabel = styled(Text)`
  color: ${getThemeColor(['midDark'])};
  margin-bottom: 11px;
  font-size: 14px;
`;

const DropZoneSelectContainer = styled(FlexColumnContainer)`
  position: absolute;
  top: 30px;
  align-items: center;
  justify-content: center;
`;

export function DropZone({ value, onChange }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (images) => {
      const imageUpload = Object.assign(images[0], {
        preview: URL.createObjectURL(images[0]),
      });
      onChange(imageUpload);
    },
  });
  return (
    <DropZoneContainer>
      <DropZoneSelectContainer>
        <AttachImageLabel>Attach Image</AttachImageLabel>
        <AttachImageIconContainer {...getRootProps()}>
          <IconContainer>
            <Icon name="attachImage" color="white" size="23" />
            <input {...getInputProps()} />
          </IconContainer>
        </AttachImageIconContainer>
      </DropZoneSelectContainer>
      {value && <Image src={value.preview} />}
    </DropZoneContainer>
  );
}

DropZone.propTypes = {
  value: shape({ preview: string }),
  onChange: func.isRequired,
};
