import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { ScreenHeaderS } from '../ProjectDetailsScreen/ProjectDetailsScreen.js';
import { InfinityList } from '../../components/InfinityList.js';
import { useComponentHeightById } from '../../hooks/useComponentHeightById.js';
import { NoResultsFoundScreen } from '../NoResultsFoundScreen.js';
import { Loader } from '../../components/Loader.js';
import { useNetworkStatus } from '../../providers/NetworkStatusProvider.js';
import {
  changeSyncQueueStatusAction,
  getIdbEntityCountsByStoreNameP,
} from '../../offline/useEntitySyncStatusQueue.js';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 24px 5px 24px;
  height: ${({ headerHeight }) => `calc(100% - ${headerHeight + 18}px)`};
`;

function EntitiesQueueList({
  entities,
  loading,
  total,
  itemKey,
  entityName,
  QueueItemComponent,
}) {
  const { componentHeight } = useComponentHeightById('screen-header', 70);

  if (loading) {
    return <Loader />;
  }

  if (total === 0) {
    return <NoResultsFoundScreen />;
  }

  return (
    <ContentWrapper headerHeight={componentHeight}>
      <InfinityList
        items={entities}
        loadMoreItems={R.identity}
        itemCount={total}
        ItemComponent={QueueItemComponent}
        entity={entityName}
        threshold={0}
        gap={20}
        itemKey={itemKey}
      />
    </ContentWrapper>
  );
}

EntitiesQueueList.propTypes = {
  entities: arrayOf(shape({})).isRequired,
  loading: bool.isRequired,
  total: number.isRequired,
  itemKey: string.isRequired,
  entityName: string.isRequired,
  QueueItemComponent: func.isRequired,
};

export function EntitiesQueueScreenBase({ indexedDbStoreName, ...props }) {
  const { isOnline } = useNetworkStatus();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOnline) {
      const updateSyncStatusP = async () => {
        const { pendingCount, failedCount, draftCounts } =
          await getIdbEntityCountsByStoreNameP(indexedDbStoreName);

        if ((pendingCount === 0 && failedCount === 0, draftCounts === 0)) {
          dispatch(
            changeSyncQueueStatusAction({
              status: null,
              itemsCount: 0,
            }),
          );
        }
      };

      updateSyncStatusP();
    }
  }, []);

  return (
    <>
      <ScreenHeaderS title="Queue page" goBack />
      <EntitiesQueueList {...props} />
    </>
  );
}

EntitiesQueueScreenBase.propTypes = {
  indexedDbStoreName: string.isRequired,
};
