import * as R from 'ramda';
import { useForm } from 'react-final-form';
import { bool, func, string, shape } from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'poly-utils';
import {
  AttachPhotoInput,
  attachPhotoFormValidators,
} from '../../components/AttachPhotoInput.js';
import { TextArea } from '../../components/TextArea.js';
import { DefaultInputS, MoneyInputAsCentsS } from '../../components/Input.js';
import { DatePicker } from '../../components/DatePicker.js';
import { PropertySelect } from '../../components/PropertySelect.js';
import { AssetTypeSelect } from '../../components/AssetTypeSelect.js';
import { AssetMakeSelect } from '../../components/AssetMakeSelect.js';
import { AssetModelSelect } from '../../components/AssetModelSelect.js';
import { AddNewAssetEntity } from '../../components/AddNewAssetEntity.js';
import { FormFieldWithLabel } from '../../components/FormFieldWithLabel.js';
import { useIsQrCodeUsedQuery } from '../ScanQrCode/hooks.js';

function DefaultInput(props) {
  return <DefaultInputS maxLength={255} {...props} type="text" />;
}

function FocusedManufacturerInput(props) {
  return <DefaultInput {...props} autoFocus />;
}

function AssetQRCodeInput({ value, onChange, ...props }) {
  const [internal, setInternal] = useState(null);
  const { isQrCodeUsed } = useIsQrCodeUsedQuery(internal, !internal);
  const form = useForm();

  const setInternalDebounced = useCallback(debounce(500)(setInternal), []);

  useEffect(() => {
    form.change('isQrCodeUsed', isQrCodeUsed);
  }, [isQrCodeUsed]);

  const handleChange = (e) => {
    const val = e.target.value;
    onChange(val);
    setInternalDebounced(val);
  };

  return (
    <DefaultInput
      onChange={handleChange}
      value={value}
      maxLength={6}
      {...props}
    />
  );
}

AssetQRCodeInput.propTypes = {
  value: string,
  onChange: func.isRequired,
  error: string,
  hasError: bool.isRequired,
};

function AddNewMake({ onChange, formSpyProps, ...props }) {
  const { form, values } = formSpyProps;

  const onChangeHandler = (e) => {
    onChange(e);

    form.change('modelId', null);
    form.change('newManufacturerName', values.searchedMake);

    if (!e) {
      form.change('searchedMake', null);
    } else {
      form.change('withNewModel', true);
    }
  };

  return (
    <AddNewAssetEntity
      {...props}
      title="+ Add Asset Make"
      onChange={onChangeHandler}
    />
  );
}

AddNewMake.propTypes = {
  onChange: func.isRequired,
  formSpyProps: shape({
    values: shape({ searchedMake: string }).isRequired,
    form: shape({ change: func.isRequired }).isRequired,
  }).isRequired,
};

function AddNewModel({ onChange, formSpyProps, ...props }) {
  const { form, values } = formSpyProps;

  const onChangeHandler = (e) => {
    onChange(e);
    form.change('newModelName', values.searchedModel);

    if (!e) {
      form.change('searchedModel', null);
    }
  };

  return (
    <AddNewAssetEntity
      {...props}
      withNewMake={values.withNewMake}
      title="+ Add Asset Model"
      onChange={onChangeHandler}
    />
  );
}

AddNewModel.propTypes = {
  onChange: func.isRequired,
  formSpyProps: shape({
    form: shape({ change: func.isRequired }).isRequired,
    values: shape({ searchedModel: string }).isRequired,
  }).isRequired,
};

export function AssetFormFields({ values }) {
  const { isAssetReplacementCostEnabled } = values;

  return (
    <>
      <FormFieldWithLabel
        required
        label="Property"
        name="propertyId"
        Component={PropertySelect}
        validators={[[R.identity, 'Property is required']]}
      />
      <FormFieldWithLabel
        required
        name="typeId"
        label="Asset Type"
        Component={AssetTypeSelect}
        validators={[[R.identity, 'Asset Type is required']]}
      />
      <FormFieldWithLabel
        name="description"
        label="Asset Description"
        Component={TextArea}
      />
      <FormFieldWithLabel
        required
        name="location"
        label="Location Description"
        Component={TextArea}
        validators={[[R.identity, 'Location Description is required']]}
      />
      <FormFieldWithLabel
        required
        withFormSpy
        label="Make"
        name={values.withNewMake ? 'newManufacturerName' : 'manufacturerId'}
        Component={
          values.withNewMake ? FocusedManufacturerInput : AssetMakeSelect
        }
        validators={[[R.identity, 'Make is required']]}
      />
      <FormFieldWithLabel
        withFormSpy
        name="withNewMake"
        Component={AddNewMake}
        {...(values.withNewMake
          ? {}
          : { label: "Didn't find the asset make?" })}
      />
      <FormFieldWithLabel
        withFormSpy
        label="Model"
        name={values.withNewModel ? 'newModelName' : 'modelId'}
        Component={
          values.withNewModel || values.withNewMake
            ? DefaultInput
            : AssetModelSelect
        }
      />
      <FormFieldWithLabel
        withFormSpy
        name="withNewModel"
        Component={AddNewModel}
        {...(values.withNewModel || values.withNewMake
          ? {}
          : { label: "Didn't find the asset model?" })}
      />
      <FormFieldWithLabel
        name="serial"
        label="Serial Number"
        Component={DefaultInput}
      />
      <FormFieldWithLabel
        name="warrantyEnd"
        label="Warranty Expiration Date"
        Component={DatePicker}
      />
      {isAssetReplacementCostEnabled && (
        <FormFieldWithLabel
          name="replacementCost"
          label="Asset Replacement Cost"
          Component={MoneyInputAsCentsS}
          componentProps={{ disabled: true }}
        />
      )}
      <FormFieldWithLabel
        required
        withFormSpy
        name="qrCodeId"
        label="Asset Tag"
        Component={AssetQRCodeInput}
        validators={[
          [R.identity, 'Asset Tag is required'],
          [
            R.compose(R.equals(6), R.length),
            'Asset Tag should have 6 characters',
          ],
        ]}
      />
      <FormFieldWithLabel
        name="photo"
        label="Asset Photo"
        Component={AttachPhotoInput}
        validators={attachPhotoFormValidators()}
      />
    </>
  );
}

AssetFormFields.propTypes = {
  values: shape({ withNewMake: bool, withNewModel: bool }).isRequired,
};
