import React from 'react';
import styled from 'styled-components';

import { string } from 'prop-types';
import { Icon } from '../../icons/Icon.js';
import { CachedEntitySyncStatuses } from '../../offline/cache/helpers.js';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  background-color: #f6f6f6;
  border-radius: 50%;
`;

export function EntityQueueIcon({ syncStatus }) {
  const iconName =
    syncStatus === CachedEntitySyncStatuses.FAILED
      ? 'documentEdit'
      : 'documentSuccess';

  return (
    <IconWrapper>
      <Icon name={iconName} />
    </IconWrapper>
  );
}

EntityQueueIcon.propTypes = {
  syncStatus: string.isRequired,
};
