import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { string } from 'prop-types';

import { DarkText, LabelText } from './Text.js';

const directionRowStyles = css`
  gap: 10px;
  margin: 0;
`;

const BlockWithLabelS = styled.div`
  display: flex;
  flex-direction: ${R.prop('direction')};
  min-width: 25%;
  gap: 4px;
  margin-bottom: 16px;
  ${({ direction }) => (direction === 'row' ? directionRowStyles : null)};
  word-break: break-word;
`;

const LabelTextS = styled(LabelText)`
  min-width: 60px;
`;

export function BlockWithLabel({ label, value, direction }) {
  return (
    <BlockWithLabelS direction={direction}>
      <LabelTextS>{label}</LabelTextS>
      <DarkText>{value}</DarkText>
    </BlockWithLabelS>
  );
}

BlockWithLabel.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
  direction: string,
};

BlockWithLabel.defaultProps = {
  direction: 'column',
};
