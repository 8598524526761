import React from 'react';

import { Text } from '../components/Text.js';
import {
  FlexColumnCentered,
  FlexColumnSpaceBetween,
} from '../components/Containers.js';
import { PhoneButtonLink } from '../components/PhoneLink.js';
import { GoHomeBtn } from '../components/Button.js';
import { WarningScreen } from './StatusScreen.js';

export function ForbiddenProjectCreationScreen() {
  return (
    <WarningScreen>
      <FlexColumnSpaceBetween>
        <FlexColumnCentered>
          <Text size={22} color="primaryBlue" lineHeight="26px">
            Project creation is not allowed
          </Text>
          <Text
            size={14}
            align="center"
            margin="12px 40px"
            color="primaryBlue"
            lineHeight="20px"
          >
            It looks like project creation is not active in our system.
          </Text>
          <Text
            size={14}
            align="center"
            margin="12px 40px"
            color="primaryBlue"
            lineHeight="20px"
          >
            If you have new information or need additional service on this
            equipment please call
          </Text>
        </FlexColumnCentered>
        <FlexColumnCentered>
          <GoHomeBtn inverted={false} caption="Go to Home Page" />
          <PhoneButtonLink inverted />
        </FlexColumnCentered>
      </FlexColumnSpaceBetween>
    </WarningScreen>
  );
}
