import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { useNavigate } from 'poly-client-routing';
import {
  insertParamsIntoURL,
  insertQueryParamsIntoURL,
} from 'poly-utils/src/url.js';

import { routes } from '../../routes.js';
import { Button } from '../../components/Button.js';

const ButtonS = styled(Button)`
  background: #c1c1c1;
`;

export function CreateWorkOrderButton({ assetQrCodeId, projectId }) {
  const navigate = useNavigate();

  const onClick = () => {
    const route = insertParamsIntoURL(
      { assetQrCodeId },
      routes.createWorkOrder,
    );
    navigate(insertQueryParamsIntoURL({ projectId }, route));
  };
  return <ButtonS onClick={onClick} caption="Create Work Order" />;
}

CreateWorkOrderButton.propTypes = {
  assetQrCodeId: string.isRequired,
  projectId: string,
};
