import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';

import {
  CloseIcon,
  Container,
  Content,
  TextContainer,
} from '../ScanQrCode/AddNew.js';
import { Text } from '../../components/Text.js';
import { StatusHeader } from '../StatusScreen.js';

import { ContactPhoneLink } from '../../components/PhoneLink.js';
import { Button } from '../../components/Button.js';

const ContainerS = styled(Container)`
  height: 320px;
`;

const ButtonS = styled(Button)`
  box-sizing: border-box;
  margin-bottom: 20px;
`;

export const assetQueueErrorModalId = 'edit-asset-queue-error-modal';

export function ErrorModal({ errorMsg, handleClose, goHome }) {
  const handleGoHome = () => {
    if (goHome) {
      goHome();
    }

    handleClose();
  };
  return (
    <ContainerS>
      <StatusHeader color="error" icon="question" />
      <CloseIcon onClick={handleClose} />
      <Content>
        <TextContainer>
          <Text size={22} align="center">
            Something went wrong
          </Text>
          <Text size={14} align="center" margin="12px 0">
            {errorMsg}
          </Text>
        </TextContainer>
        {goHome && (
          <ButtonS
            inverted
            caption="Go Home"
            iconName="home"
            onClick={handleGoHome}
          />
        )}
        <ContactPhoneLink size={14} color="secondaryDark" />
      </Content>
    </ContainerS>
  );
}

ErrorModal.propTypes = {
  errorMsg: string.isRequired,
  handleClose: func.isRequired,
  goHome: func.isRequired,
};
