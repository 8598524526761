import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { arrayOf, func, shape, string } from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, useRouterQuery } from 'poly-client-routing';
import * as R from 'ramda';
import styled from 'styled-components';
import { useUploadAttachment } from 'poly-client-utils/src/files/useUploadAttachment.js';
import { insertParamsIntoURL } from 'poly-utils/src/url.js';

import { FormScreenContainer } from './AssetForm/AddAssetScreen.js';
import { ScreenTitle } from '../components/ScreenTitle.js';
import { LogoHeader } from '../components/LogoHeader.js';
import { FormFieldWithLabel } from '../components/FormFieldWithLabel.js';
import { TextArea } from '../components/TextArea.js';
import { FormS, FormWrapperS } from './AssetForm/styles.js';
import { DropZone } from '../components/DropZone.js';
import { Button } from '../components/Button.js';
import { AsyncSelectWithSearch } from '../components/Select/AsyncSelectWithSearch.js';
import { ErrorText } from '../components/Text.js';
import { routes } from '../routes.js';
import { SuppliersSelector } from './SupplierCheckIn/SelectSupplierScreen.js';

const CreateWOFromWrapper = styled(FormWrapperS)`
  justify-content: space-around;
  height: 100%;
`;

const assetByQrCodeIdQuery = gql`
  query assetByQrCodeId($input: AssetByQrCodeIdInput!) {
    assetByQrCodeId(input: $input) {
      _id
      checkInProjects {
        _id
        projectId
        suppliers {
          supplierId
          supplierName
        }
      }
    }
  }
`;

const createAssetWorkOrderMutation = gql`
  mutation createAssetWorkOrder($input: CreateAssetWorkOrderInput!) {
    createAssetWorkOrder(input: $input) {
      projectId
    }
  }
`;

// prepareRelatedProjectsOptions :: [Project] -> [Option]
const prepareRelatedProjectsOptions = R.map(
  R.applySpec({ label: R.prop('projectId'), value: R.prop('_id') }),
);

function WorkOrderRelatedSelect({ activeProjects, ...props }) {
  const options = prepareRelatedProjectsOptions(activeProjects);

  return <AsyncSelectWithSearch {...props} options={options} />;
}

WorkOrderRelatedSelect.propTypes = {
  activeProjects: arrayOf(
    shape({ _id: string.isRequired, projectId: string.isRequired }),
  ),
};

function CreateWorkOrderFrom({
  handleSubmit,
  projectId,
  activeProjects,
  formId,
}) {
  return (
    <FormS onSubmit={handleSubmit} id={formId}>
      <FormFieldWithLabel
        required
        name="description"
        label="Work Order comments"
        Component={TextArea}
        componentProps={{ maxLength: 1000 }}
        validators={[
          [R.when(R.is(String), R.trim), 'Work Order comments are required'],
        ]}
      />
      <FormFieldWithLabel
        name="cloneFromProjectId"
        label="Related Project"
        Component={WorkOrderRelatedSelect}
        componentProps={{ activeProjects, isDisabled: !!projectId }}
      />
      <FormFieldWithLabel
        name="upload"
        label="Upload File"
        Component={DropZone}
      />
    </FormS>
  );
}

CreateWorkOrderFrom.propTypes = {
  handleSubmit: func.isRequired,
  activeProjects: arrayOf(
    shape({ _id: string.isRequired, projectId: string.isRequired }),
  ),
  projectId: string,
  formId: string.isRequired,
};

// getCreateWorkOrderInitialValues :: (QueryData, String) -> CreateWOInitialValues
const getCreateWorkOrderInitialValues = (data, projectId) =>
  R.compose(
    R.mergeRight({
      description: '',
      upload: {},
    }),
    R.applySpec({
      assetId: R.path(['assetByQrCodeId', '_id']),
      cloneFromProjectId: R.compose(
        R.prop('_id'),
        R.find(R.propEq('projectId', projectId)),
        R.pathOr([], ['assetByQrCodeId', 'checkInProjects']),
      ),
    }),
  )(data);

const createWorkOrderFormId = 'createWorkOrderFormId';

// getCurrentProjectSuppliers :: QueryData, String) -> [Supplier]
const getCurrentProjectSuppliers = (data, projectId) =>
  R.compose(
    R.propOr([], 'suppliers'),
    R.find(R.propEq('projectId', projectId)),
    R.pathOr([], ['assetByQrCodeId', 'checkInProjects']),
  )(data);

export function CreateWorkOrderScreen() {
  const { assetQrCodeId } = useParams();
  const { projectId, supplierId } = useRouterQuery(['projectId']);
  const [error, setError] = useState('');
  const [currentSupplierId, setCurrentSupplierId] = useState(supplierId);
  const navigate = useNavigate();

  const [createAssetWorkOrder, { loading }] = useMutation(
    createAssetWorkOrderMutation,
  );

  const uploadFile = useUploadAttachment();

  const { data, loading: assetLoading } = useQuery(assetByQrCodeIdQuery, {
    variables: { input: { qrCodeId: assetQrCodeId } },
    fetchPolicy: 'network-only',
    skip: !assetQrCodeId,
  });

  const projectSuppliers = getCurrentProjectSuppliers(data, projectId);

  useEffect(() => {
    if (!currentSupplierId && projectSuppliers.length === 1) {
      setCurrentSupplierId(R.path([0, 'supplierId'], projectSuppliers));
    }
  }, [data]);

  const activeProjects = R.pathOr(
    [],
    ['assetByQrCodeId', 'checkInProjects'],
    data,
  );

  if (assetLoading) {
    return 'Loading...';
  }

  const initialValues = getCreateWorkOrderInitialValues(data, projectId);

  const handleSubmit = async (values) => {
    const { upload } = values;

    try {
      setError('');
      let uploadedFileId = null;

      if (upload?.path) {
        uploadedFileId = await uploadFile(upload);
      }

      const input = {
        ...R.omit(['upload'], values),
        ...(uploadedFileId ? { uploadedFileId } : {}),
        supplierId: currentSupplierId,
      };

      await createAssetWorkOrder({ variables: { input } });

      navigate(
        insertParamsIntoURL({ assetQrCodeId }, routes.workOrderCreatedSuccess),
      );
    } catch (e) {
      setError(e.message);
    }
  };
  return (
    <FormScreenContainer>
      <LogoHeader>
        <ScreenTitle>Create Work Order</ScreenTitle>
      </LogoHeader>
      <CreateWOFromWrapper>
        {!currentSupplierId ? (
          <SuppliersSelector
            suppliers={projectSuppliers}
            selectedSupplierId={currentSupplierId}
            setSelectedSupplierId={setCurrentSupplierId}
          />
        ) : (
          <>
            <Form
              initialValues={initialValues}
              onSubmit={handleSubmit}
              render={CreateWorkOrderFrom}
              activeProjects={activeProjects}
              projectId={projectId}
              formId={createWorkOrderFormId}
            />
            {error && <ErrorText>{error}</ErrorText>}
            <Button
              type="submit"
              form={createWorkOrderFormId}
              width="100%"
              caption="Submit Work Order"
              loading={loading}
            />
          </>
        )}
      </CreateWOFromWrapper>
    </FormScreenContainer>
  );
}
