import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 58px;
  height: 58px;
  background: transparent;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  z-index: 0;
  animation: ${rotate} 1s linear infinite;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #858585 0deg,
    rgba(20, 20, 20, 0) 360deg
  );
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 50%;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
  }
`;

const FlexCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled(FlexCentered)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled(FlexCentered)`
  width: 88px;
  height: 82px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 8px;
`;

export function Loader() {
  return (
    <Overlay>
      <Container>
        <Spinner />
      </Container>
    </Overlay>
  );
}
