import React from 'react';
import { string } from 'prop-types';

import styled from 'styled-components';
import { Icon } from '../../icons/Icon.js';
import { ColorTextS, StatusContainerS } from '../ProjectStatus.js';
import { CachedEntitySyncStatuses } from '../../offline/cache/helpers.js';

const entityStatusesConfig = {
  [CachedEntitySyncStatuses.PENDING]: {
    color: '#F5AD20',
    background: '#FFEEC4',
    text: 'Pending',
    iconName: 'dots',
  },
  [CachedEntitySyncStatuses.FAILED]: {
    color: '#E75858',
    background: '#FFCEDA',
    text: 'Failed',
    iconName: 'blocked',
  },
  [CachedEntitySyncStatuses.SUCCESS]: {
    color: '#85BB49',
    background: 'rgba(132, 188, 69, 0.32)',
    text: 'Successful',
    iconName: 'success',
  },
  [CachedEntitySyncStatuses.DRAFT]: {
    color: '#F5AD20',
    background: '#FFEEC4',
    text: 'Draft',
    iconName: 'dots',
  },
};

const EntityQueueStatusContainerS = styled(StatusContainerS)`
  height: 22px;
`;

export function EntityQueueStatus({ syncStatus }) {
  const config = entityStatusesConfig[syncStatus];
  if (!config) {
    return null;
  }

  const { color, text, background, iconName } = config;
  return (
    <EntityQueueStatusContainerS background={background}>
      <Icon width="16" height="16" name={iconName} />
      <ColorTextS color={color}>{text}</ColorTextS>
    </EntityQueueStatusContainerS>
  );
}

EntityQueueStatus.propTypes = {
  syncStatus: string.isRequired,
};
