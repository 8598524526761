import React, { useState } from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import {
  FlexColumnCentered,
  FlexColumnContainer,
  FlexColumnSpaceBetween,
} from '../../components/Containers.js';
import { Button } from '../../components/Button.js';
import { ErrorText, Text } from '../../components/Text.js';
import { Loader } from './Loader.js';
import { inputStyles } from '../../components/Input.js';
import { isCodeValid } from './common.js';

const Container = styled(FlexColumnSpaceBetween)`
  padding-top: 40px;
`;

const AssetQRCodeInputS = styled.input`
  ${inputStyles};
  width: 100%;
  height: 45px;
`;

const InputWithLabelContainer = styled(FlexColumnContainer)`
  width: 100%;
  input {
    margin: 8px 0;
  }
`;
export function EnterCode({ setQrCodeId, loading }) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const onClick = () => {
    if (!isCodeValid(code)) {
      return setError('Asset Tag should have 6 characters');
    }
    return setQrCodeId(code);
  };

  const onChange = (e) => {
    setError('');
    setCode(e.target.value);
  };

  return (
    <>
      <Container>
        <InputWithLabelContainer>
          <Text size={14} color="midDark">
            Enter Code
          </Text>
          <AssetQRCodeInputS
            label="Enter Code"
            type="text"
            maxLength={6}
            value={code}
            hasError={error}
            onChange={onChange}
          />
          <ErrorText>{error}</ErrorText>
        </InputWithLabelContainer>

        <FlexColumnCentered>
          <Button caption="Enter Code" onClick={onClick} />
        </FlexColumnCentered>
      </Container>
      {loading && <Loader />}
    </>
  );
}

EnterCode.propTypes = {
  setQrCodeId: func.isRequired,
  loading: bool,
};
