import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useForm, Form } from 'react-final-form';
import { bool, shape, string } from 'prop-types';

import { useUpdateQueryParams, useNavigate } from 'poly-client-routing';

import { routes } from '../../routes.js';
import { editAssetFormId } from './constants.js';
import { Button } from '../../components/Button.js';
import { AssetFormFields } from './AssetFormFields.js';
import { FlexColumnSpaceBetween } from '../../components/Containers.js';
import { FormContainer, FormFooterContainer, FormS } from './styles.js';
import { validateAssetFormQRCode } from './assetFormHelpers.js';
import { AssetPreview } from './AssetPreview.js';
import { useAddAssetForLogic } from './useAddAssetForLogic.js';

export const FormScreenContainer = styled(FlexColumnSpaceBetween)`
  height: 100%;
`;

function FormC({ values }) {
  const updateQueryParams = useUpdateQueryParams();
  const navigate = useNavigate();
  const form = useForm();
  const formState = form.getState();

  useEffect(() => {
    updateQueryParams({ valid: formState.valid });
  }, [formState.valid]);

  return (
    <FormContainer
      title="Add New Asset"
      onClose={() => navigate(routes.scanQrCode)}
    >
      <AssetFormFields values={values} />
    </FormContainer>
  );
}

FormC.propTypes = {
  values: shape({ withNewMake: bool, withNewModel: bool }).isRequired,
};

function AddAssetForm({ handleSubmit, values }) {
  return (
    <FormS id={editAssetFormId} onSubmit={handleSubmit}>
      <FormC values={values} />
    </FormS>
  );
}

AddAssetForm.propTypes = {
  handleSubmit: string.isRequired,
  values: shape({ withNewMake: bool, withNewModel: bool }).isRequired,
};

export function AddAssetScreen() {
  const {
    handlePreview,
    assetLoading,
    loading,
    isPreview,
    assetQrCodeId,
    propertyId,
    assetDraft,
  } = useAddAssetForLogic();

  if (assetLoading) {
    return null;
  }

  return (
    <FormScreenContainer>
      {isPreview ? (
        <AssetPreview />
      ) : (
        <>
          <Form
            onSubmit={handlePreview}
            render={AddAssetForm}
            validate={validateAssetFormQRCode}
            initialValues={{
              qrCodeId: assetQrCodeId,
              propertyId,
              ...(assetDraft || {}),
            }}
          />
          <FormFooterContainer>
            <Button
              type="submit"
              form={editAssetFormId}
              iconName="document"
              caption="Preview Asset Details"
              loading={loading}
            />
          </FormFooterContainer>
        </>
      )}
    </FormScreenContainer>
  );
}
