import React from 'react';
import { PatternFormat } from 'react-number-format';
import styled, { css } from 'styled-components';
import { MoneyInputAsCents } from 'poly-admin-ui';
import { func, string, bool } from 'prop-types';
import { getThemeColor, getThemeFont } from 'poly-book-admin';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
`;

const InputLabel = styled.label`
  color: ${getThemeColor(['midDark'])};
  font-family: ${getThemeFont(['defaultFont'])};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
`;

export const inputStyles = css`
  background: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-family: ${getThemeFont(['defaultFont'])};
  color: ${getThemeColor(['primaryLight'])};
  &:focus {
    outline: none;
  }
  font-variant-ligatures: none;

  ${({ hasError, invalid }) =>
    (hasError || invalid === 'true') && 'border: 1px solid #e75858'};
`;

const NumberInput = styled(PatternFormat)`
  ${inputStyles};
  width: 295px;
  height: 45px;
`;

export const DefaultInputS = styled.input`
  ${inputStyles};

  height: 45px;
`;

export const MoneyInputAsCentsS = styled(MoneyInputAsCents)`
  input {
    ${inputStyles};

    height: 45px;

    ${({ disabled }) => (disabled ? 'color: #9a9da0;' : '')}
  }
`;

export function Input({ label, value, onChange, hasError, name, ...props }) {
  return (
    <InputContainer>
      {!!label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <NumberInput
        value={value}
        name={name}
        onChange={onChange}
        {...(hasError ? { invalid: hasError.toString() } : {})}
        id={name}
        {...props}
      />
    </InputContainer>
  );
}

Input.propTypes = {
  label: string,
  onChange: func,
  hasError: bool,
  onValueChange: func,
  name: string.isRequired,
  value: string.isRequired,
};
