import { useEffect } from 'react';

export const useDeleteSyncedEntities = ({ hasPermission, isAllowedTab }) => {
  useEffect(() => {
    if (hasPermission && isAllowedTab) {
      const deleteSyncedEntitiesWorker = new Worker(
        new URL('./deleteSyncedEntitiesWorker.js', import.meta.url),
      );

      deleteSyncedEntitiesWorker.postMessage(true);
    }
  }, [hasPermission]);
};
