import React from 'react';

import {
  createGlobalStyle,
  ThemeProvider as ThemeProviderBase,
} from 'styled-components';
import { node } from 'prop-types';

const defaultTheme = {
  colors: {
    primaryRegular: '#263369',
    primaryBlue: '#12347A',
    midDark: '#999999',
    primaryLight: '#58628D',
    secondaryDark: '#09B2BD',
    secondaryRegular: '#49DBDB',
    midLight: '#EBEBEB',
    scaleMid: '#BCBCBC',
    error: '#dd6262',
    primaryDark: '#1a1514',
    primaryDark2: '#202223',
    primaryLight2: '#5e6271',
    success: '#1AB394',
    warning: '#ffb841',
    mainDark: '#272728',
  },
  fonts: {
    defaultFont: 'TTNormsMedium',
  },
};

const GlobalStyle = createGlobalStyle`
    body {
      font-variant-ligatures: none;
      font-family: 'TTNormsMedium', sans-serif;
    }
`;

export function ThemeProvider({ children }) {
  return (
    <ThemeProviderBase theme={defaultTheme}>
      <GlobalStyle />
      {children}
    </ThemeProviderBase>
  );
}

ThemeProvider.propTypes = {
  children: node.isRequired,
};
