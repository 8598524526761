import React from 'react';
import * as R from 'ramda';
import { string, shape, bool } from 'prop-types';

import { ScreenTitle } from './ScreenTitle.js';
import { Image } from './Image.js';
import { LogoHeader } from './LogoHeader.js';

export function AssetDetailsHeaderWithLogo({ asset, hideAssetImage }) {
  if (!asset) {
    return null;
  }

  const assetPhotoUrl = R.path(['photo', 'url'], asset);
  return (
    <>
      <LogoHeader>
        <ScreenTitle>
          ({asset.qrCodeId}) {asset.type?.name}
        </ScreenTitle>
      </LogoHeader>

      {!hideAssetImage && assetPhotoUrl && <Image src={assetPhotoUrl} />}
    </>
  );
}

AssetDetailsHeaderWithLogo.propTypes = {
  asset: shape({
    type: shape({ name: string.isRequired }),
    qrCodeId: string,
    photo: shape({ url: string.isRequired }),
  }),
  hideAssetImage: bool,
};
