import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { string, bool, arrayOf, shape, func } from 'prop-types';

import { Loader } from '../Loader.js';
import { inputStyles } from '../Input.js';
import { Icon } from '../../icons/Icon.js';

const iconStyles = css`
  position: absolute;
  top: 35%;
  right: 5%;
  background: #fff;
  z-index: 1;
`;

const SelectWrapperS = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const SelectS = styled.select`
  ${inputStyles};

  ${({ value }) => !value && 'color: #a5aabc;'};

  -webkit-appearance: none;
  appearance: none;
  width: 100%;
`;

const DownArrowIconS = styled(Icon)`
  ${iconStyles};
`;

const LoaderS = styled(Loader)`
  ${iconStyles};
  right: -85%;
`;

const ClearIconS = styled(Icon)`
  ${iconStyles};

  padding: 5px 10px;
  width: 8px;
  right: 10%;
  top: 20%;
`;

const PlaceholderS = styled.option`
  display: none;
`;

// changeSelectValue :: Function -> Option -> _
const changeSelectValue = (changeHandler) =>
  R.compose(changeHandler, R.path(['target', 'value']));

export function Select({
  value,
  options,
  onChange,
  hasError,
  className,
  isLoading,
  isDisabled,
  isClearable,
  placeholder,
  ...props
}) {
  const onSelectClear = () => onChange(null);

  return (
    <SelectWrapperS className={className}>
      <SelectS
        {...props}
        value={value}
        invalid={R.toString(hasError)}
        disabled={isLoading || isDisabled}
        onChange={changeSelectValue(onChange)}
      >
        <PlaceholderS value="" disabled hidden>
          {placeholder}
        </PlaceholderS>
        {options.map(({ label, value: optionValue }) => (
          <option key={optionValue} value={optionValue}>
            {label}
          </option>
        ))}
      </SelectS>
      {isLoading ? <LoaderS size={16} /> : <DownArrowIconS name="down" />}
      {!!value && isClearable && (
        <ClearIconS name="close" onClick={onSelectClear} />
      )}
    </SelectWrapperS>
  );
}

Select.propTypes = {
  value: string,
  hasError: bool,
  isLoading: bool,
  isDisabled: bool,
  isClearable: bool,
  className: string,
  placeholder: string,
  onChange: func.isRequired,
  options: arrayOf(shape({ value: string, label: string })).isRequired,
};
