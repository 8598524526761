import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { SUPPLIER_CHECK_IN_PROJECT_PERMISSION } from 'poly-security';

export const useUserHasAccessToCheckInProject = () => {
  const hasAccess = useHasUserAccessWithPermission(
    SUPPLIER_CHECK_IN_PROJECT_PERMISSION,
  );

  return hasAccess;
};
