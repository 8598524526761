import { SET_LIST_SCROLL } from './actions.js';

const defaultState = { offset: 0 };

export const listScrollReducer = (state, action) => {
  if (action?.type === SET_LIST_SCROLL) {
    return action.payload ? { ...state, ...action.payload } : defaultState;
  }

  return state || defaultState;
};
