import React from 'react';
import { useNavigate, useParams } from 'poly-client-routing';
import { insertParamsIntoURL } from 'poly-utils/src/url.js';

import { Text } from '../components/Text.js';
import {
  FlexColumnCentered,
  FlexColumnSpaceBetween,
} from '../components/Containers.js';
import { PhoneButtonLink } from '../components/PhoneLink.js';
import { SuccessScreen } from './StatusScreen.js';
import { Button } from '../components/Button.js';
import { routes } from '../routes.js';

export function WorkOrderCreatedScreen() {
  const { assetQrCodeId } = useParams();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(insertParamsIntoURL({ assetQrCodeId }, routes.createProject));
  };

  return (
    <SuccessScreen>
      <FlexColumnSpaceBetween>
        <FlexColumnCentered>
          <Text size={16} color="primaryDark" align="center">
            Work Order is created. Check-In to the project from the home screen
          </Text>
        </FlexColumnCentered>
        <FlexColumnCentered>
          <Button onClick={onClick} caption="Go to the Main Screen" />
          <PhoneButtonLink inverted />
        </FlexColumnCentered>
      </FlexColumnSpaceBetween>
    </SuccessScreen>
  );
}
