import { Link as LinkBase } from 'poly-client-routing';
import styled from 'styled-components';
import { getThemeColor, getThemeFont } from 'poly-book-admin';

export const Link = styled(LinkBase)`
  color: ${getThemeColor(['midDark'])};
  font-family: ${getThemeFont(['defaultFont'])};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;
