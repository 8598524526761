import React from 'react';
import styled from 'styled-components';

const ButtonS = styled.button`
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  display: inline;
  padding: 0;
  margin: 0;
  text-decoration: none;
`;

export function TransparentButton(props) {
  return <ButtonS {...props} />;
}
