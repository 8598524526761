import { shape, string, node } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AssetDetailsHeaderWithLogo } from '../components/AssetDetailsHeaderWithLogo.js';
import { FlexColumnCentered } from '../components/Containers.js';

const Wrapper = styled(FlexColumnCentered)`
  justify-content: space-between;
  padding: 20px 24px;
  flex-grow: 1;
`;

export function AssetDetailsContainer({ asset, children }) {
  return (
    <>
      <AssetDetailsHeaderWithLogo asset={asset} />
      <Wrapper>{children}</Wrapper>
    </>
  );
}

AssetDetailsContainer.propTypes = {
  asset: shape({
    qrCodeId: string,
    type: shape({ name: string.isRequired }),
    photo: shape({
      url: string.isRequired,
    }),
  }),
  children: node.isRequired,
};
