import { useCallback } from 'react';
import { debounce } from 'poly-utils';

export const useSetInputValueById = (id) => {
  const input = document.getElementById(id);

  const blurInputDebounced = useCallback(
    debounce(400)(() => {
      input?.blur();
    }),
    [],
  );

  return (value) => {
    if (input) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      ).set;

      nativeInputValueSetter.call(input, value);

      const inputEvent = new Event('input', { bubbles: true });

      input.dispatchEvent(inputEvent);

      blurInputDebounced();
    }
  };
};
