import React from 'react';
import * as R from 'ramda';
import { useNavigate, useParams } from 'poly-client-routing';
import styled from 'styled-components';
import { getThemeColor } from 'poly-book-admin';
import { arrayOf, shape, string } from 'prop-types';

import { useAssetDetailsByQrCode } from '../hooks/useAssetDetails.js';
import { Text } from '../components/Text.js';
import { routes } from '../routes.js';
import { FlexColumnCentered } from '../components/Containers.js';
import { Button } from '../components/Button.js';
import { InfoWithPhoneText } from './InfoWithPhoneText.js';
import { WarningScreen } from './StatusScreen.js';
import { PhoneButtonLink } from '../components/PhoneLink.js';
import { Redirect } from '../components/Navigation.js';

const TextS = styled(Text)`
  margin: 10px 0;
  text-align: center;
`;

const TextContainer = styled(FlexColumnCentered)`
  justify-content: flex-start;
  margin: 0 40px;
`;

// getAssetActiveProjects :: Asset -> [AssetProject]
const getAssetActiveProjects = R.pathOr([], ['activeProjects']);

// getExistingProjectId :: Asset -> String
const getAssetProjectId = R.compose(
  R.prop('projectId'),
  R.head,
  getAssetActiveProjects,
);

// getExistingProjectId :: Asset -> [AssetProjectSupplier]
const getAssetExistingProjectSuppliers = R.compose(
  R.pathOr([], ['suppliers']),
  R.head,
  getAssetActiveProjects,
);

// wrapSupplierName :: String -> String
const wrapSupplierName = (str) => `"${str}"`;

// formatSingleSupplierMsg :: String -> String
const formatSingleSupplierMsg = (supplier) =>
  `The service provider ${supplier} has already been dispatched`;

// formatMultiSuppliersMsg :: String -> String
const formatMultiSuppliersMsg = (suppliers) =>
  `The service providers: ${suppliers} have already been dispatched`;

// formatMsg ::[AssetScannerSupplier] -> String
const formatMsg = R.compose(
  R.ifElse(
    R.propSatisfies(R.gt(R.__, 1), 'length'),
    R.compose(formatMultiSuppliersMsg, R.join(',')),
    formatSingleSupplierMsg,
  ),
  R.map(R.compose(wrapSupplierName, R.prop('supplierName'))),
);

function TextWithSuppliers({ suppliers }) {
  if (suppliers.length > 0) {
    return <TextS size={14}>{formatMsg(suppliers)}</TextS>;
  }

  return (
    <TextS>
      Our service center is diligently addressing the issue related to this
      equipment.
    </TextS>
  );
}

TextWithSuppliers.propTypes = {
  suppliers: arrayOf(shape({ supplierName: string.isRequired })),
};

export const ProjectIdText = styled(Text)`
  color: ${getThemeColor(['primaryLight'])};
  font-size: 14px;
  margin: 12px 0;
`;

export function ExistingProjectScreen() {
  const { assetQrCodeId } = useParams();
  const navigate = useNavigate();

  const { asset, loading } = useAssetDetailsByQrCode(assetQrCodeId);

  const projectId = getAssetProjectId(asset);

  const suppliers = getAssetExistingProjectSuppliers(asset);

  if (loading) {
    return 'Loading...';
  }

  if (!projectId) {
    return <Redirect route={routes.helpDefault} />;
  }

  return (
    <WarningScreen>
      <>
        <TextContainer>
          <Text size={24}>Existing Project</Text>
          <ProjectIdText>#{projectId}</ProjectIdText>
          <TextWithSuppliers suppliers={suppliers} />
          <InfoWithPhoneText />
        </TextContainer>
        <FlexColumnCentered>
          <Button
            iconName="home"
            inverted={false}
            caption="Go to Home Page"
            onClick={() => navigate(routes.home)}
          />
          <PhoneButtonLink inverted />
        </FlexColumnCentered>
      </>
    </WarningScreen>
  );
}
