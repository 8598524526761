import React from 'react';

import { Text } from '../components/Text.js';

export function InfoWithPhoneText() {
  return (
    <Text align="center" size={14} margin="10px 0">
      if you have new information or need additional service on this equipment
      please call
    </Text>
  );
}
