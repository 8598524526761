import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { READ_CLIENT_PERMISSION } from 'poly-security';
import { useHasUserAccessWithPermission } from 'poly-client-utils/src/hooks/useHasUserAccessWithPermission.js';

import { SET_GLOBAL_CLIENT } from '../redux/currentClient/actions.js';
import { GLOBAL_CLIENT_SELECTED_ID_KEY } from '../components/GlobalClientSelect.js';

const setGlobalClientAction = (clientId) => ({
  type: SET_GLOBAL_CLIENT,
  payload: { clientId },
});

export const useGlobalClient = () => {
  const clientId = localStorage.getItem(GLOBAL_CLIENT_SELECTED_ID_KEY);

  const hasPermissionToReadClient = useHasUserAccessWithPermission(
    READ_CLIENT_PERMISSION,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (clientId && hasPermissionToReadClient) {
      dispatch(setGlobalClientAction(clientId));
    } else {
      dispatch(setGlobalClientAction(null));
    }
  }, []);
};
